import gql from "graphql-tag";

export const QUERY_BANDS = gql`
    query bands($contest: ID!, $perPage: Int, $currentPage: Int, $search: String, $orderBy: BandsOrderBy) {
        data: bands(contest: $contest, perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
                status
                votes
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_BAND = gql`
    query band($id: ID!) {
        data: band(id: $id) {
            id
            name
            email
            phone
            address
            city
            zipCode
            image
            video
            videoUrl
            phoneToVote
            status
            mentors {
                id
                name
            }
            showrooms {
                id
                name
            }
            votes
            description
            members {
                name
                role
            }
            metatags
            facebookUrl
            instagramUrl
            youtubeUrl
            twitterUrl
            tiktokUrl
        }
    }
`;

export const QUERY_MENTORS = gql`
    query mentors($perPage: Int, $currentPage: Int, $search: String, $filters: InputMentorsFilters, $orderBy: MentorsOrderBy) {
        data: mentors(perPage: $perPage, currentPage: $currentPage, search: $search, filters: $filters, orderBy: $orderBy) {
            rows {
                id
                name
            }
        }
    }
`;