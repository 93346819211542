import React from "react";
import styled, { css } from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import Loading from "../Loading";

const MediStrapProperties = MediStrap.getProperties();

const styles = {
    brand: {
        background: MediStrapProperties.colors.brand100,
        border: MediStrapProperties.colors.brand100,
        hover: MediStrapProperties.colors.brand120,
        hoverBorder: MediStrapProperties.colors.brand120,
        disabled: MediStrapProperties.colors.brand10,
        disabledColor: MediStrapProperties.colors.brand30,
        disabledBorder: MediStrapProperties.colors.brand30,
        activated: MediStrapProperties.colors.brand100,
        activatedColor: MediStrapProperties.colors.white100,
        color: MediStrapProperties.colors.white100
    },
    grey: {
        background: MediStrapProperties.colors.grey60,
        border: MediStrapProperties.colors.grey60,
        hover: MediStrapProperties.colors.grey90,
        hoverBorder: MediStrapProperties.colors.grey90,
        disabled: MediStrapProperties.colors.grey10,
        disabledColor: MediStrapProperties.colors.grey20,
        disabledBorder: MediStrapProperties.colors.grey20,
        activated: MediStrapProperties.colors.grey60,
        activatedColor: MediStrapProperties.colors.white100,
        color: MediStrapProperties.colors.white100
    },
    outlinedBrand: {
        background: "transparent",
        border: MediStrapProperties.colors.brand100,
        hover: MediStrapProperties.colors.brand10,
        hoverBorder: MediStrapProperties.colors.brand100,
        disabled: "transparent",
        disabledColor: MediStrapProperties.colors.brand30,
        disabledBorder: MediStrapProperties.colors.brand30,
        activated: MediStrapProperties.colors.brand100,
        activatedColor: MediStrapProperties.colors.white100,
        color: MediStrapProperties.colors.brand100
    },
    red: {
        background: MediStrapProperties.colors.alert100,
        border: MediStrapProperties.colors.alert100,
        hover: MediStrapProperties.colors.alert120,
        hoverBorder: MediStrapProperties.colors.alert120,
        disabled: MediStrapProperties.colors.grey10,
        disabledColor: MediStrapProperties.colors.grey20,
        disabledBorder: MediStrapProperties.colors.grey10,
        activated: MediStrapProperties.colors.alert100,
        activatedColor: MediStrapProperties.colors.white100,
        color: MediStrapProperties.colors.white100
    },
    empty: {
        background: "transparent",
        border: "transparent",
        hover: "transparent",
        hoverBorder: "transparent",
        disabled: "transparent",
        disabledColor: MediStrapProperties.colors.grey20,
        disabledBorder: "transparent",
        activated: "transparent",
        activatedColor: MediStrapProperties.colors.brand100,
        color: MediStrapProperties.colors.grey60
    }
}

export default function Button(
    {
        label,
        icon,
        color = "brand",
        loading = false,
        disabled,
        size = "normal",
        width,
        children,
        ...props
    }
) {
    return (
        <Btn
            width={width}
            disabled={!!disabled || !!loading}
            loading={!!loading}
            color={color}
            size={size}
            {...props}
        >
            {(!!icon && !loading) && <Block mr={(!!icon && !!label) ? 8 : 0}><Icon color={styles[color].color} className={`icon-${icon}`} /></Block>}
            {(!!loading && !!label) && "Loading..."}
            {(!loading && !!label) && label}
            {/* {!!label && <Text color={disabled ? styles[color].disabledColor : styles[color].color} size={14} height={24} ml={!!icon && !loading ? 8 : 0}>{!loading ? label : "Loading..."}</Text>} */}
            {loading ? <Block ml={!!label ? 8 : 0}><Loading color={styles[color].color} /></Block> : children}
        </Btn>
    );
}

const Icon = styled.span`
    ::before {
        color: ${({ color }) => color};
    }
`;

const Btn = styled.button`
    font-family: "AvertaSemiBold";
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    border: ${({ color }) => `1px solid ${styles[color].border}`};
    width: ${({ width }) => !!width ? width : "fit-content"};
    border-radius: ${MediStrapProperties.style.borderRadius}px;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    background-color: ${({ color }) => styles[color].background};
    color: ${({ color }) => styles[color].color};

    ${({ size }) => {
        switch (size) {
            case "medium": return css`height: 40px;padding: 12px 24px;`;
            case "small": return css`height: 32px;padding: 8px 16px;`;
            case "square": return css`width: 48px;height: 48px;`;
            case "squareMedium": return css`width: 32px;height: 32px;`;
            case "squareSmall": return css`width: 24px;height: 24px;`;
            default: return css`height: 48px;padding: 12px 24px;`;
        }
    }}

    :hover {
        background-color: ${({ color }) => styles[color].hover};
        border-color: ${({ color }) => styles[color].hoverBorder};
    }

    :active {
        background-color: ${({ color }) => styles[color].activated};
        color: ${({ color }) => styles[color].activatedColor};

        ${Icon} {
            ::before {
                color: ${({ color }) => styles[color].activatedColor};
            }
        }
    }

    ${({ loading, color }) => !loading && css`
        :disabled {
            background-color: ${styles[color].disabled};
            border-color: ${({ color }) => styles[color].disabledBorder};
            color: ${({ color }) => styles[color].disabledColor};
        }
    `}

    :disabled {
        cursor: auto;
    }
`;