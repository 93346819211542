import React from "react";
import styled from "styled-components";
import MediStrap from "../../MediStrap";

const MediStrapProperties = MediStrap.getProperties();

export default function Icon(
    {
        name,
        color = MediStrapProperties.colors.brand100
    }
) {
    return (
        <Element className={`icon-${name}`} color={color} />
    );
}

const Element = styled.span`
    ::before {
        color: ${({ color }) => color};
    }
`;