import React, { useEffect, useRef, useState } from "react";
import styled, { css } from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from '../../Block';
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function DropDownMultiInput(
    {
        label,
        description,
        error,
        disabled,
        placeholder,
        onChangeValue,
        numberOfVisibleOptions = 2,
        options = [],
        value = []
    }
) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    const handleClickOutside = (event) => {
        if (ref && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const onClick = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    }

    const onClickOption = (option) => {
        if (disabled) { return; }

        const selectedOptionIndex = value.findIndex((selectedOption) => selectedOption.value === option.value);

        if (selectedOptionIndex !== -1) {
            value.splice(selectedOptionIndex, 1);
        } else {
            value.push(option);
        }

        onChangeValue(value);
    }

    return (
        <Block ref={ref} width="100%">
            {!!label && <Text size={12} height={16} mb={8} color={MediStrapProperties.colors.grey100}>{label}</Text>}
            <Block style={{ position: "relative" }}>
                {(!error) && <AngleWrapper disabled={disabled} onClick={onClick}>{isOpen ? <span className="icon-Angle_up" /> : <span className="icon-Angle_down" />}</AngleWrapper>}
                {(!!error) && <StatusWrapper><span className="icon-Error" /></StatusWrapper>}
                <Input
                    error={error}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {(value.length === 0 && !!placeholder) && <Text size={14} height={24} color={MediStrapProperties.colors.grey50}>{placeholder}</Text>}
                    {(value.length > 0) &&
                        <SelectedOptionsWrapper>
                            {value.slice(0, numberOfVisibleOptions).map((selectedOption, index) => {
                                return (
                                    <SelectedOption disabled={disabled} key={index}>
                                        <SelectedOptionText>
                                            <Text size={12} height={16} color={disabled ? MediStrapProperties.colors.grey20 : MediStrapProperties.colors.brand100} mr={4}>{selectedOption.label}</Text>
                                        </SelectedOptionText>
                                        <DeleteIcon className="icon-Delete" onClick={(e) => { e.stopPropagation(); onClickOption(selectedOption); }} disabled={disabled} />
                                    </SelectedOption>
                                );
                            })}
                            {(value.length > numberOfVisibleOptions) &&
                                <SelectedOptionEllipsis disabled={disabled}>
                                    <Text size={12} height={16} color={disabled ? MediStrapProperties.colors.grey20 : MediStrapProperties.colors.brand100}>...</Text>
                                </SelectedOptionEllipsis>
                            }
                        </SelectedOptionsWrapper>
                    }
                </Input>
                {isOpen && <Options>
                    {options.map((option, index) => {
                        const selected = !!value.find((selectedOption) => selectedOption.value === option.value);
                        return (
                            <Option key={index} onClick={() => onClickOption(option)}>
                                <Text size={14} height={24} color={selected ? MediStrapProperties.colors.grey100 : MediStrapProperties.colors.grey50}>{option.label}</Text>
                                {(selected) && <Checked className="icon-Check" />}
                            </Option>
                        );
                    })}
                </Options>}
            </Block>
            {(() => {
                let color = null;
                let text = null;
                if (!!error) { text = typeof error === "string" ? error : null; color = MediStrapProperties.colors.alert100; }
                else if (!!description) { text = description; color = MediStrapProperties.colors.grey50; }

                if (!!text) {
                    return (
                        <Block mt={8}>
                            <Text
                                size={11}
                                height={16}
                                color={color}
                            >
                                {error || description}
                            </Text>
                        </Block>
                    );
                }
            })()}
        </Block>
    );
}

export default React.memo(DropDownMultiInput);

const Input = styled.div`
    background-color: ${MediStrapProperties.colors.white100};
    height: 48px;
    color: ${MediStrapProperties.colors.grey100};
    font-size: 14px;
    line-height: 24px;
    font-family: "AvertaSemiBold";
    border: 0;
    padding: 12px 40px 12px 16px;
    border: 1px solid ${MediStrapProperties.colors.grey20};
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    user-select: none;
    
    ${({ disabled }) => !disabled && css`
        cursor: pointer;
    `}

    :hover {
        border-color: ${({ error }) => {
        if (!!error) {
            return MediStrapProperties.colors.alert100;
        }

        return MediStrapProperties.colors.grey60;
    }};
    }

    ${({ disabled }) => !!disabled && css`
        color: ${MediStrapProperties.colors.grey20};
        background-color: ${MediStrapProperties.colors.grey5};
        border-color: ${MediStrapProperties.colors.grey20};
    `}

    ${({ error }) => !!error && css`
        border-color:  ${MediStrapProperties.colors.alert100};
    `}
`;

const DeleteIcon = styled.span`
    ::before {
        color: ${({ disabled }) => !!disabled ? MediStrapProperties.colors.grey20 : MediStrapProperties.colors.brand100};
    }
`;

const AngleWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;


    ${({ disabled }) => !disabled && css`
        cursor: pointer;
    `}
`;

const StatusWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;

const Options = styled.div`
    position: absolute;
    top: 56px;
    left: 0px;
    right: 0px;
    max-height: 128px;
    background-color: ${MediStrapProperties.colors.white100};
    border: 1px solid ${MediStrapProperties.colors.brand100};
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    z-index: 3;
    overflow-y: auto;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
    }
`;

const Checked = styled.span`
    position: absolute;
    top: 12px;
    right: 12px;
    ::before {
        color: ${MediStrapProperties.colors.brand100};
    }
`;

const Option = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    width: 100%;
    padding: 0 40px 0 16px;

    :hover {
        background-color: ${MediStrapProperties.colors.brand100};

        span {
            color: ${MediStrapProperties.colors.white100} !important;
        }

        ${Checked}::before {
            color: ${MediStrapProperties.colors.white100};
        }
    }
`;


const SelectedOptionsWrapper = styled.div`
    display: flex;
    flex-flow: row;
    margin: 0 -4px;
`;

const SelectedOption = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    max-width: 113px;
    height: 24px;
    background-color: ${MediStrapProperties.colors.brand10};
    margin: 0 4px;
    padding: 0 4px 0 6px;

    ${({ disabled }) => !!disabled && css`
        background-color: ${MediStrapProperties.colors.grey10};
    `}
`;

const SelectedOptionText = styled.div`
    max-width: 81px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SelectedOptionEllipsis = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 22px;
    height: 24px;
    background-color: ${MediStrapProperties.colors.brand10};
    margin: 0 4px;

    ${({ disabled }) => !!disabled && css`
        background-color: ${MediStrapProperties.colors.grey10};
    `}
`;
