import { Formik } from 'formik';
import React from 'react';
import { Components } from '../../MediStrap';
import colors from '../../utils/colors';

function DesignSystem() {

    const options = [
        { value: "example1", label: "ExampleExampleExample 1" },
        { value: "example2", label: "ExampleExampleExampleExampleExampleExample 2" },
        { value: "example3", label: "Example 3" }
    ]

    return (
        <Components.Block flex width="100%" color={colors.white} pl={50} pr={50}>
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} error={"Error message information"} Component={Components.TextInput} name="textInputError1" icon="Error" label="Text input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} success={"Success message information"} Component={Components.TextInput} name="textInputSuccess1" icon="Error" label="Text input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} loading={"Validation message information"} Component={Components.TextInput} name="textInputLoading1" icon="Error" label="Text input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} Component={Components.TextInput} name="textInput21" label="Text input" placeholder="Input fill text" icon="Error" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input disabled initialValue={"José Gomes"} Component={Components.TextInput} name="textInputDisabled" label="Text input" placeholder="Input fill text" icon="Error" />
                            </Components.Block> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} error={"Error message information"} Component={Components.PasswordInput} name="textInputError1" label="Password input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} success={"Success message information"} Component={Components.PasswordInput} name="textInputSuccess1" label="Password input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} loading={"Validation message information"} Component={Components.PasswordInput} name="textInputLoading1" label="Password input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} Component={Components.PasswordInput} name="textInput21" label="Password input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input disabled initialValue={"José Gomes"} Component={Components.PasswordInput} name="textInputDisabled" label="Password input" placeholder="Input fill text" />
                            </Components.Block> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} error={"Error message information"} Component={Components.DropdownInput} name="textInputError1" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} Component={Components.DropdownInput} name="textInput21" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} disabled description={"Input filling information"} initialValue={{ value: "example1", label: "Example 1" }} Component={Components.DropdownInput} name="textInputDisabled" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} error={"Error message information"} Component={Components.DropdownMultiInput} name="textInputError1" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} Component={Components.DropdownMultiInput} name="textInput21" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} disabled description={"Input filling information"} initialValue={[options[0]]} Component={Components.DropdownMultiInput} name="textInputDisabled" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} error={"Error message information"} Component={Components.ValueWithSelectInput} name="textInputError1" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} Component={Components.ValueWithSelectInput} name="textInput21" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} disabled description={"Input filling information"} initialValue={{ number: "2", select: options[0] }} Component={Components.ValueWithSelectInput} name="textInputDisabled" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} error={"Error message information"} Component={Components.DateInput} name="textInputError1" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} Component={Components.DateInput} name="textInput21" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} disabled description={"Input filling information"} initialValue={"2021-10-22"} Component={Components.DateInput} name="textInputDisabled" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block> */}

                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} error={"Error message information"} Component={Components.UploadInput} name="textInputError1" label="Text input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} success={"Success message information"} Component={Components.UploadInput} name="textInputSuccess1" label="Text input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} loading={"Validation message information"} Component={Components.UploadInput} name="textInputLoading1" label="Text input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input description={"Input filling information"} Component={Components.UploadInput} name="textInput21" label="Text input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input disabled Component={Components.UploadInput} name="textInputDisabled" label="Text input" placeholder="Input fill text" />
                            </Components.Block>

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} error={"Error message information"} Component={Components.QuantityInput} name="textInputError1" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} Component={Components.QuantityInput} name="textInput21" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} disabled description={"Input filling information"} initialValue={"25"} Component={Components.QuantityInput} name="textInputDisabled" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block> */}

                            {/* <Components.Navigation /> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} error={"Error message information"} Component={Components.TextAreaInput} name="textInputError1" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} description={"Input filling information"} Component={Components.TextAreaInput} name="textInput21" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input options={options} disabled description={"Input filling information"} initialValue={"25"} Component={Components.TextAreaInput} name="textInputDisabled" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Input min={1000} max={10000} description={"Input filling information"} error={"Error message information"} Component={Components.SliderInput} name="textInputError1" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input min={1000} max={10000} description={"Input filling information"} Component={Components.SliderInput} name="textInput21" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Input min={1000} max={10000} disabled description={"Input filling information"} initialValue={null} Component={Components.SliderInput} name="textInputDisabled" label="Dropdown input" placeholder="Input fill text" />
                            </Components.Block> */}

                            {/* <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" icon="Error" size="small" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" loading={true} size="small" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" disabled size="small" />
                            </Components.Block>

                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" icon="Check" color="grey" size="small" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" loading={true} color="grey" size="small" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" disabled color="grey" size="small" />
                            </Components.Block>

                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" icon="Check" color="outlinedBrand" size="small" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" loading={true} color="outlinedBrand" size="small" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button label="Button text" disabled color="outlinedBrand" size="small" />
                            </Components.Block>

                            <Components.Block mt={24} width={334}>
                                <Components.Button icon="Check" color="outlinedBrand" size="square" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button disabled icon="Check" color="outlinedBrand" size="square" />
                            </Components.Block>

                            <Components.Block mt={24} width={334}>
                                <Components.Button icon="Check" color="outlinedBrand" size="squareMedium" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button disabled icon="Check" color="outlinedBrand" size="squareMedium" />
                            </Components.Block>

                            <Components.Block mt={24} width={334}>
                                <Components.Button icon="Check" color="outlinedBrand" size="squareSmall" />
                            </Components.Block>
                            <Components.Block mt={24} width={334}>
                                <Components.Button disabled icon="Check" color="outlinedBrand" size="squareSmall" />
                            </Components.Block> */}
                        </form>
                    )
                }}
            </Formik>
        </Components.Block>
    );
}

export default DesignSystem;
