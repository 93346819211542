import React from "react";
import AccordionItem from "../../AccordionItem";
import Block from "../../Block";
import Button from "../../Button";
import DeleteLine from "../../DeleteLine";
import FieldArray from "../../FieldArray";
import FormGroup from "../../FormGroup";
import Input from "../../Input";
import Portlet from "../../Portlet";
import TextInput from "../TextInput";

function AccordionMembers({ name, value = [], anchor, errors }) {
    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <Portlet
                        anchor={anchor}
                        noInnerPadding
                        icon="Paper"
                        title="Membros"
                        actions={
                            <Button
                                type="button"
                                label="Adicionar membro"
                                color="brand"
                                size="small"
                                onClick={() =>
                                    arrayHelpers.insert(value.length + 1, {})
                                }
                            />
                        }
                    >
                        {value.map((_item, index) => {
                            return (
                                <AccordionItem
                                    key={index}
                                    index={index}
                                    name={name}
                                    title={"Membro " + " #" + (index + 1)}
                                >
                                    <FormGroup title="Membros">
                                        <Block>
                                            <Input
                                                Component={TextInput}
                                                name={`${name}[${index}].name`}
                                                label="Nome"
                                                error={errors[`${name}[${index}].name`]}
                                            />
                                        </Block>
                                        <Block>
                                            <Input
                                                Component={TextInput}
                                                name={`${name}[${index}].role`}
                                                label="Função"
                                                error={errors[`${name}[${index}].role`]}
                                            />
                                        </Block>
                                    </FormGroup>
                                    <FormGroup>
                                        <DeleteLine
                                            title="Eliminar membro"
                                            description="Esta ação irá eliminar os dados relativos ao membro permanentemente"
                                            onClickDelete={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        />
                                    </FormGroup>
                                </AccordionItem>
                            );
                        })}
                    </Portlet>
                );
            }}
        />
    );
}

export default AccordionMembers;
