import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import colors from "../../../../utils/colors";
import Block from '../../Block';
import Text from '../../Text';

const modulesQuill = {
    toolbar: {
        container: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }, { 'background': [] }, 'link', 'image', 'emoji'],
        ],
        handlers: {
            'color': function (value) {
                if (value == 'custom-color') value = window.prompt('Enter Hex Color Code');
                this.quill.format('color', value);
            }
        }
    }
};

function HTMLInput(
    {
        description,
        error,
        onChangeValue,
        value = ""
    }
) {
    return (
        <Block width="100%">
            <Block style={{ position: "relative" }}>
                <ReactQuill modules={modulesQuill} value={value} onChange={onChangeValue} />
            </Block>
            {(() => {
                let color = null;
                let text = null;
                if (!!error) { text = typeof error === "string" ? error : null; color = colors.red01; }
                else if (!!description) { text = description; color = colors.grey50; }

                if (!!text) {
                    return (
                        <Block mt={8}>
                            <Text
                                size={11}
                                height={16}
                                color={color}
                            >
                                {error || description}
                            </Text>
                        </Block>
                    );
                }
            })()}
        </Block>
    );
}

export default React.memo(HTMLInput);