import React from "react";
import styled, { css } from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from '../../Block';
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function RadioInput(
    {
        label,
        onChangeValue,
        radioValue,
        value
    }
) {
    const selected = value === radioValue;
    return (
        <Block row center pointer width="fit-content" onClick={(e) => { e.stopPropagation(); !!onChangeValue && onChangeValue(radioValue); }}>
            <Block row center>
                <OutsideCircle middle center selected={selected}>
                    <Circle selected={selected} />
                </OutsideCircle>
                {!!label && <Text noselect size={14} height={24} ml={8} color={selected ? MediStrapProperties.colors.grey100 : MediStrapProperties.colors.grey50}>{label}</Text>}
            </Block>
        </Block>
    );
}

export default React.memo(RadioInput);

const OutsideCircle = styled(Block)`
    width: 16px;
    height: 16px;
    border-radius: 8px;

    ${({ selected }) => css`
        background-color: ${MediStrapProperties.colors.white100};
        border: 1px solid ${selected ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.grey30};
    `}
`;

const Circle = styled(Block)`
    width: 12px;
    height: 12px;
    border-radius: 6px;

    ${({ selected }) => css`
        background-color: ${selected ? MediStrapProperties.colors.brand100 : "transparent"};
        border: 1px solid ${selected ? MediStrapProperties.colors.brand100 : "transparent"};
    `}
`;