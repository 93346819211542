import gql from "graphql-tag";

export const QUERY_FAQ_SUBJECTS = gql`
    query faqSubjects($perPage: Int, $currentPage: Int, $search: String, $orderBy: FaqSubjectsOrderBy) {
        data: faqSubjects(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_FAQ_SUBJECT = gql`
    query faqSubject($id: ID!) {
        data: faqSubject(id: $id) {
            id
            name
        }
    }
`;

export const QUERY_FAQS = gql`
    query faqs($perPage: Int, $currentPage: Int, $search: String, $orderBy: FaqsOrderBy) {
        data: faqs(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                question
                answer
                subjects {
                    id
                    name
                }
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_FAQ = gql`
    query faq($id: ID!) {
        data: faq(id: $id) {
            id
            question
            answer
            subjects {
                id
                name
            }
        }
    }
`;