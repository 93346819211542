import React, { useState } from "react";
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import Icon from "../Icon";
import PortletLine from "../PortletLine";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

export default function AccordionItem(
    {
        title,
        custom,
        children
    }
) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper>
            <Block pointer onClick={() => setIsOpen(!isOpen)}>
                <PortletLine
                    left={
                        !!title ?
                            <Text size={14} height={24} color={MediStrapProperties.colors.grey100}>{title}</Text>
                            :
                            custom
                    }
                    right={
                        <Icon name={isOpen ? "Angle_up" : "Angle_down"} color={MediStrapProperties.colors.grey60} />
                    }
                />
            </Block>
            <Content color={MediStrapProperties.colors.grey5} open={isOpen}>
                {children}
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    border-top: 1px solid ${MediStrapProperties.colors.grey20};

    :first-of-type {
        border-top: none;
    }
`;

const Content = styled(Block)`
    height: ${({ open }) => !!open ? "fit-content" : "0px"};
    overflow: ${({ open }) => !!open ? "auto" : "hidden"};
    padding: ${({ open }) => !!open ? "24px" : "0px"};
    border-top: ${({ open }) => !!open ? "1px" : "0px"} solid ${MediStrapProperties.colors.grey20};
`;