export const CONSTANT_PER_PAGE_OPTIONS = [
    { label: "10 linhas", value: 10 },
    { label: "50 linhas", value: 50 },
    { label: "100 linhas", value: 100 }
];

export const CONSTANT_BANDS_STATUS = {
    WAITING_APPROVAL: 'Waiting approval',
    APPROVED: 'Approved'
}

export const CHECK_IF_FILE_IS_IMAGE_REQUIRED = (file) => {
    if (!!file) {
        return ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type);
    }
    return false;
}

export const CHECK_IF_FILE_IS_IMAGE = (file) => {
    if (!!file && typeof file !== "string") {
        return ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type);
    }
    return true;
}

export const CHECK_IF_FILE_IS_VIDEO_REQUIRED = (file) => {
    if (!!file) {
        return ['video/mp4', 'video/ogg', 'video/webm'].includes(file.type);
    }
    return false;
}

export const CHECK_IF_FILE_IS_VIDEO = (file) => {
    if (!!file && typeof file !== "string") {
        return ['video/mp4', 'video/ogg', 'video/webm'].includes(file.type);
    }
    return true;
}