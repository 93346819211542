import { useLazyQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { Components, MediStrap } from "../../MediStrap";
import { CONSTANT_PER_PAGE_OPTIONS } from "../../utils/commonData";
import { QUERY_MENTORS } from "../Mentors/graphql/queries";
import { QUERY_CONTESTS } from "./graphql/queries";
import ModalCreate from "./ModalCreate";
import ModalUpdate from "./ModalUpdate";
import { useHistory } from "react-router-dom";

const MediStrapProperties = MediStrap.getProperties();
const perPageOptions = CONSTANT_PER_PAGE_OPTIONS;

function Contests() {
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [rowId, setRowId] = useState(false);

    const history = useHistory();

    const [data, setData] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            // orderBy: "name_ASC"
        },
    });

    const [orderBy, setOrderBy] = useState(null);
    const [search, setSearch] = useState("");

    const [query] = useLazyQuery(QUERY_CONTESTS, {
        onError: () => {},
        onCompleted: (result) => {
            if (result && result.data) {
                setData(result.data);
            }
        },
    });

    const request = (params = {}) => {
        const { perPage, currentPage } = params;

        query({
            variables: {
                perPage: perPage || data.pagination.perPage,
                currentPage: currentPage || data.pagination.currentPage,
                search: search !== "" ? search : undefined,
                orderBy: !!orderBy
                    ? `${orderBy.column}_${orderBy.order}`
                    : undefined,
            },
        });
    };

    useEffect(() => {
        request({ perPage: data.pagination.perPage, currentPage: 1 });
    }, [search, orderBy]);

    const [dataMentors, setDataMentors] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 100,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            // orderBy: "name_ASC"
        },
    });

    const [queryFaqSubjects] = useLazyQuery(QUERY_MENTORS, {
        onError: () => {},
        onCompleted: (result) => {
            if (result && result.data) {
                setDataMentors(result.data);
            }
        },
    });

    const requestFaqSubjects = (params = {}) => {
        const { perPage, currentPage } = params;

        queryFaqSubjects({
            variables: {
                perPage: perPage || dataMentors.pagination.perPage,
                currentPage: currentPage || dataMentors.pagination.currentPage,
            },
        });
    };

    useEffect(() => {
        requestFaqSubjects({
            perPage: dataMentors.pagination.perPage,
            currentPage: 1,
        });
    }, []);

    return (
        <>
            <Components.Block row space="between">
                <Components.Block row>
                    <Components.Block width={444}>
                        <Components.TextInput
                            icon="Search"
                            placeholder="Procurar"
                            value={search}
                            onChangeValue={(value) => setSearch(value)}
                        />
                    </Components.Block>
                    <Components.Block row center width={163} ml={20}>
                        <Components.DropdownInput
                            onChangeValue={(option) =>
                                request({
                                    perPage: option.value,
                                    currentPage: 1,
                                })
                            }
                            value={perPageOptions.find(
                                (item) => item.value == data.pagination.perPage
                            )}
                            options={perPageOptions}
                        />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Navigation
                            perPage={data.pagination.perPage}
                            currentPage={data.pagination.currentPage}
                            total={data.pagination.total}
                            hasPreviousPage={data.pagination.hasPreviousPage}
                            hasNextPage={data.pagination.hasNextPage}
                            onClickPrevious={() =>
                                request({
                                    currentPage:
                                        data.pagination.currentPage - 1,
                                })
                            }
                            onClickNext={() =>
                                request({
                                    currentPage:
                                        data.pagination.currentPage + 1,
                                })
                            }
                        />
                    </Components.Block>
                </Components.Block>
                <Components.Block row>
                    <Components.Button
                        color="brand"
                        label="Criar"
                        onClick={() => setModalCreateOpen(true)}
                    />
                </Components.Block>
            </Components.Block>
            <Components.Block flex pt={32} style={{ overflow: "hidden" }}>
                <Components.AdvancedTable
                    columns={[
                        {
                            label: "Data de início",
                            value: "startDate",
                            order: false,
                        },
                        {
                            label: "Data de fim",
                            value: "endDate",
                            width: 380,
                            order: false,
                        },
                        {
                            label: "",
                            value: "bands",
                            width: 380,
                            order: false,
                        },
                    ]}
                    rows={data.rows.map((row) => {
                        return {
                            startDate: <Components.Block row center height={56}>
                            <Components.Block mr={12} pointer>
                                <Components.Button type="button" icon="Edit" size="squareSmall" color="empty" onClick={() => { setRowId(row.id); setModalUpdateOpen(true); }} />
                            </Components.Block>
                            <Components.Block>
                                <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={row.startDate}>{row.startDate}</Components.Text>
                            </Components.Block>
                        </Components.Block>,
                            endDate: row.endDate,
                            bands: <Components.Block row right>
                                <Components.Button type="button" label="Ver bandas" size="medium" color="brand" onClick={() => history.push(`/dashboard/contests/${row.id}/bands`)} />
                            </Components.Block>
                        };
                    })}
                    noBorderRadius
                />
            </Components.Block>

            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => request({ currentPage: 1 })}
                isOpen={isModalCreateOpen}
                mentorsOptions={dataMentors.rows.map((item) => {
                    return { label: item.name, value: item.id };
                })}
            />

            <ModalUpdate
                onRequestClose={() => {
                    setRowId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updateRow) => {
                    const index = data.rows.findIndex(
                        (item) => item.id == updateRow.id
                    );
                    data.rows[index] = updateRow;
                    setData({ ...data });
                }}
                onDelete={() => {
                    request();
                }}
                isOpen={isModalUpdateOpen}
                dataId={rowId}
                mentorsOptions={dataMentors.rows.map((item) => {
                    return { label: item.name, value: item.id };
                })}
            />
        </>
    );
}

export default Contests;
