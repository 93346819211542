import React from "react";
import styled from 'styled-components';
import MediStrap from "../../MediStrap";
import Block from '../Block';
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

function AdvancedTable({ columns = [], rows = [], orderBy, onClickOrderBy, noBorderRadius, noContentBorder, backgroundColor = MediStrapProperties.colors.grey10 }) {
    const borderRadiusTop = !!noBorderRadius ? "0px" : `${MediStrapProperties.style.borderRadius}px`;

    return (
        <Wrapper flex color={backgroundColor} style={{ borderTopLeftRadius: borderRadiusTop, borderTopRightRadius: borderRadiusTop }} noContentBorder={noContentBorder}>
            <Columns row center height={40}>
                {columns.map((column, columnIndex) => {
                    return (
                        <Column key={columnIndex} row center flex={!column.width} width={column.width} space="between">
                            <Text size={12} height={16} color={MediStrapProperties.colors.grey50}>{column.label}</Text>
                            {!!column.order &&
                                <Block pointer mr={8} onClick={() => !!onClickOrderBy && onClickOrderBy(column.value, !!orderBy && orderBy.column === column.value ? orderBy.order === "ASC" ? "DESC" : "ASC" : "ASC")}>
                                    <span className="icon-Angles_down_up"></span>
                                </Block>
                            }
                        </Column>
                    );
                })}
            </Columns>

            <Rows flex color={backgroundColor}>
                {rows.map((row, rowIndex) => {
                    return (
                        <Row key={rowIndex}>
                            <Block row flex>
                                {columns.map((column, columnIndex) => {
                                    return (
                                        <RowCell key={columnIndex} flex={!column.width} width={column.width}>
                                            {typeof row[column.value] === "string" ?
                                                <Text size={14} height={24} color={MediStrapProperties.colors.grey100}>{row[column.value]}</Text>
                                                :
                                                row[column.value]
                                            }
                                        </RowCell>
                                    );
                                })}
                            </Block>
                        </Row>
                    );
                })}
            </Rows>
        </Wrapper>
    );
}

export default AdvancedTable;

const Wrapper = styled(Block)`
    overflow: auto;
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    border: 1px solid ${MediStrapProperties.colors.grey20};
    border-left: ${({ noContentBorder }) => noContentBorder ? "0px" : "1px"} solid ${MediStrapProperties.colors.grey20};
    border-right: ${({ noContentBorder }) => noContentBorder ? "0px" : "1px"} solid ${MediStrapProperties.colors.grey20};
`;

const Columns = styled(Block)`
    position: sticky;
    top: 0;
`;

const Column = styled(Block)`
    height: 100%;
    min-width: 131px;
    padding-left: 16px;
    border-bottom: 1px solid ${MediStrapProperties.colors.grey20};
    background-color: ${MediStrapProperties.colors.grey10};

    :first-of-type {
        padding-left: 12px;
    }
`;

const Rows = styled(Block)`
    
`;

const RowCell = styled(Block)`
    height: 56px;
    min-width: 131px;
    border-bottom: 1px solid ${MediStrapProperties.colors.grey20};
    padding: 0 16px;

    justify-content: center;
    
    :first-of-type {
        padding-left: 12px;
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const Row = styled(Block)`
    width: 100%;
    height: 56px;
    flex-flow: row !important;

    ${RowCell} {
        background-color: ${MediStrapProperties.colors.white100};
    }
`;