import React from "react";
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import PortletLine from "../PortletLine";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

export default function Portlet(
    {
        anchor,
        icon,
        title,
        actions,
        noInnerPadding,
        children
    }
) {
    return (
        <Wrapper color={MediStrapProperties.colors.white100} className={anchor}>
            <PortletLine
                left={
                    <Block row center>
                        {!!icon && <Block mr={8}>
                            <Icon className={`icon-${icon}`} />
                        </Block>}
                        {!!title && <Block>
                            <Text size={16} height={24} color={MediStrapProperties.colors.grey100}>{title}</Text>
                        </Block>}
                    </Block>
                }
                right={
                    <Block>
                        {actions}
                    </Block>
                }
            />
            <Block height={1} color={MediStrapProperties.colors.grey20} />
            <Block pt={!!noInnerPadding ? 0 : 24} pr={!!noInnerPadding ? 0 : 24} pb={!!noInnerPadding ? 0 : 24} pl={!!noInnerPadding ? 0 : 24}>
                {children}
            </Block>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    margin-bottom: 24px;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Icon = styled.span`
    ::before {
        color: ${MediStrapProperties.colors.brand100};
    }
`;