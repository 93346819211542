import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Block from "../Block";
import Text from "../Text";

export default function TabMenuLinkless(
    {
        tabs = [],
        setSelectedTab,
        selectedTab
    }
) {
    const location = useLocation();
    return (
        <Block row flex>
            {tabs.map((tab, index) => {
                return (<TabItem
                    key={index}
                    flex
                    br={5}
                    onClick={() => setSelectedTab(tab)}
                    color={tab === selectedTab ? '#fff' : undefined}
                    pb={8}
                    pt={8}
                    pl={8}
                    pb={8}
                >
                    <Text size={12} height={16}>{tab.name}</Text>
                </TabItem>)
            })}
        </Block>
    );
}

const TabItem = styled(Block)`
    border-bottom: 1px solid #ffffff;
    cursor: pointer;
`;
