import gql from "graphql-tag";

export const MUTATION_FAQ_SUBJECT_CREATE = gql`
    mutation faqSubjectCreate($name: String!) {
        data: faqSubjectCreate(name: $name) {
            id
            name
        }
    }
`;
export const MUTATION_FAQ_SUBJECT_UPDATE = gql`
    mutation faqSubjectUpdate($id: ID!, $name: String) {
        data: faqSubjectUpdate(id: $id, name: $name) {
            id
            name
        }
    }
`;
export const MUTATION_FAQ_SUBJECT_DELETE = gql`
    mutation faqSubjectDelete($id: ID!) {
        data: faqSubjectDelete (id: $id) {
            id
            name
        }
    }
`;
export const MUTATION_FAQ_CREATE = gql`
    mutation faqCreate($question: String!, $answer: String!, $subjects: [ID!]!) {
        data: faqCreate(question: $question, answer: $answer, subjects: $subjects) {
            id
            question
            answer
            subjects {
                id
                name
            }
        }
    }
`;
export const MUTATION_FAQ_UPDATE = gql`
    mutation faqUpdate($id: ID!, $question: String, $answer: String, $subjects: [ID!]) {
        data: faqUpdate(id: $id, question: $question, answer: $answer, subjects: $subjects) {
            id
            question
            answer
            subjects {
                id
                name
            }
        }
    }
`;
export const MUTATION_FAQ_DELETE = gql`
    mutation faqDelete($id: ID!) {
        data: faqDelete (id: $id) {
            id
            question
            answer
            subjects {
                id
                name
            }
        }
    }
`;