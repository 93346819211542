import { useState } from "react";
import { Components, MediStrap } from "../../../MediStrap";
import ModalCreate from "./ModalCreate";
import ModalUpdate from "./ModalUpdate";

const MediStrapProperties = MediStrap.getProperties();

function ModalFaqSubjects({ onRequestClose, faqSubjects, onCreate, onUpdate, onDelete, isOpen }) {
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [rowId, setRowId] = useState(false);



    return (
        <Components.ModalThatSlides
            title="Tópicos"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <Components.Block row space="between" width={200}>
                <Components.Button color="brand" label="Criar" onClick={() => setModalCreateOpen(true)} />
            </Components.Block>
            <Components.Block flex pt={32} style={{ overflow: "hidden" }}>
                <Components.AdvancedTable
                    columns={[
                        {
                            label: "Nome",
                            value: "name",
                            order: false
                        }
                    ]}
                    rows={faqSubjects.rows.map((row) => {
                        return ({
                            name: <Components.Block row center height={56}>
                                <Components.Block mr={12} pointer>
                                    <Components.Button type="button" icon="Edit" size="squareSmall" color="empty" onClick={() => { setRowId(row.id); setModalUpdateOpen(true); }} />
                                </Components.Block>
                                <Components.Block>
                                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title="Sigma">{row.name}</Components.Text>
                                </Components.Block>
                            </Components.Block>
                        })
                    })}
                    noBorderRadius
                />
            </Components.Block>

            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => onCreate()}
                isOpen={isModalCreateOpen}
            />

            <ModalUpdate
                onRequestClose={() => {
                    setRowId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updateRow) => {
                    onUpdate(updateRow);
                }}
                onDelete={() => {
                    onDelete();
                }}
                isOpen={isModalUpdateOpen}
                dataId={rowId}
            />
        </Components.ModalThatSlides>
    );
}

export default ModalFaqSubjects;
