import React, { useState } from "react";
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from "../../Block";
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function SliderInput(
    {
        label,
        onChangeValue,
        min = 0,
        max = 0,
        symbol = "",
        value
    }
) {
    const [innerValue, setInnerValue] = useState(value || [min, max]);

    return (
        <Wrapper>
            <Block row space="between" mb={8}>
                {!!label && <Text size={12} height={16} bold color={MediStrapProperties.colors.grey100}>{label}</Text>}
                <Text size={12} height={16} color={MediStrapProperties.colors.brand100}>{innerValue[0]}{symbol} - {innerValue[1]}{symbol}</Text>
            </Block>
            <SliderWrapper>
                <StyledSlider
                    min={min}
                    max={max}
                    defaultValue={innerValue}
                    renderTrack={Track}
                    renderThumb={Thumb}
                    onChange={setInnerValue}
                    onAfterChange={onChangeValue}
                />
            </SliderWrapper>
        </Wrapper>
    );
}

export default React.memo(SliderInput);

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    user-select: none;
`;

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 46px;
    background-color: ${MediStrapProperties.colors.white100};
`;

const StyledThumb = styled.div`
    height: 46px;
    width: 1px;
    background-color: ${MediStrapProperties.colors.brand100};
`;

const Thumb = (props, state) => <StyledThumb {...props} />;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => props.index === 2 ? MediStrapProperties.colors.white : props.index === 1 ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.white};
    opacity: ${props => props.index === 1 ? 0.3 : 1};
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const SliderWrapper = styled.div`
    overflow: hidden;
    border: 1px solid ${MediStrapProperties.colors.grey20};
    border-radius: 4px;

    :hover {
        border: 1px solid ${MediStrapProperties.colors.grey60};
    }
`;