import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Components, MediStrap } from "../../../MediStrap";
import {
    CHECK_IF_FILE_IS_IMAGE,
    CHECK_IF_FILE_IS_VIDEO,
} from "../../../utils/commonData";
import AccordionPrizes from "../AccordionPrizes";
import {
    MUTATION_CONTEST_DELETE,
    MUTATION_CONTEST_UPDATE,
} from "../graphql/mutations";
import { QUERY_CONTEST } from "../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({
    onRequestClose,
    onUpdate,
    onDelete,
    mentorsOptions,
    isOpen,
    dataId,
}) {
    const updateFormRef = useRef(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [data, setData] = useState(null);

    const [query] = useLazyQuery(QUERY_CONTEST, {
        onError: () => { },
        onCompleted: (result) => {
            if (result && result.data) {
                setData(result.data);
            }
        },
    });

    const request = (params = {}) => {
        const { id } = params;

        query({
            variables: {
                id,
            },
        });
    };

    useEffect(() => {
        if (!!dataId) {
            request({ id: dataId });
        }
    }, [dataId]);

    const [mutationDataUpdate, { loading: loadingDataUpdate }] = useMutation(
        MUTATION_CONTEST_UPDATE,
        {
            onError: () => { },
            onCompleted: (result) => {
                if (result && result.data) {
                    onUpdate(result.data);
                    onRequestClose();
                }
            },
        }
    );

    const updateData = (params = {}) => {
        const {
            name,
            active,
            startDate,
            endDate,
            registrationDate,
            regulation,
            mentors,
            prizes,
            slider,
            legal,
            metatags,
            contactEmail,
        } = params;

        mutationDataUpdate({
            variables: {
                id: data.id,
                name,
                active,
                startDate,
                endDate,
                registrationDate,
                regulation,
                mentors,
                prizes,
                slider,
                legal,
                metatags,
                contactEmail,
            },
        });
    };

    const [mutationDataDelete, { loading: loadingDataDelete }] = useMutation(
        MUTATION_CONTEST_DELETE,
        {
            onError: () => { },
            onCompleted: (result) => {
                if (result && result.data) {
                    onDelete();
                    onRequestClose();
                }
            },
        }
    );

    const deleteData = () => {
        mutationDataDelete({
            variables: {
                id: data.id,
            },
        });
    };

    useEffect(() => {
        if (!isOpen) {
            setData(null);
        }
    }, [isOpen]);

    return (
        <Components.ModalThatSlides
            id="editar"
            title="Editar"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button
                            loading={loadingDataDelete}
                            size="square"
                            icon="Trash"
                            color="red"
                            onClick={() => setIsDeleteModalOpen(true)}
                        />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Button
                            type="submit"
                            loading={loadingDataUpdate}
                            label="Editar"
                            color="brand"
                            onClick={() => updateFormRef.current.handleSubmit()}
                        />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Geral", anchor: "general" },
                {
                    icon: "Notes",
                    title: "Regulamento",
                    anchor: "regulation",
                },
                {
                    icon: "Notes",
                    title: "Legal",
                    anchor: "legal",
                },
                { icon: "Anexo", title: "Slider", anchor: "slider" },
                {
                    icon: "Contacts",
                    title: "Mentores",
                    anchor: "mentors",
                },
                {
                    icon: "Paper",
                    title: "Metatags",
                    anchor: "metatags",
                },
            ]}
        >
            {!!data ? (
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            name: data.name,
                            active: data.active,
                            mentors: data.mentors.map((item) => {
                                return { value: item.id, label: item.name };
                            }),
                            socialNetworks: data.socialNetworks,
                            slider: data.slider.map((item) => {
                                return {
                                    mediaType: item.mediaType,
                                    imageSource: item.image,
                                    videoSource: item.video,
                                    smallTitle: item.smallTitle,
                                    title: item.title,
                                    subtitle: item.subtitle,
                                    description: item.description,
                                };
                            }),
                            startDate: data.startDate,
                            endDate: data.endDate,
                            registrationDate: data.registrationDate,
                            regulation: data.regulation,
                            prizes: data.prizes,
                            legal: data.legal,
                            metatagsDescription: !!data.metatags && data.metatags.description,
                            metatagsTitle: !!data.metatags && data.metatags.title,
                            contactEmail: data.contactEmail,
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required(),
                            active: yup.boolean().required(),
                            startDate: yup.string().required(),
                            endDate: yup.string().required(),
                            registrationDate: yup.string().required(),
                            regulation: yup.array(
                                yup.object().shape({
                                    title: yup.string().required(),
                                    text: yup.string(),
                                })
                            ),
                            legal: yup.array(
                                yup.object().shape({
                                    title: yup.string().required(),
                                    text: yup.string().required(),
                                })
                            ),
                            prizes: yup
                                .array(
                                    yup.object().shape({
                                        place: yup.string().required(),
                                        items: yup
                                            .array(
                                                yup.object().shape({
                                                    text: yup
                                                        .string()
                                                        .required(),
                                                    overline: yup
                                                        .boolean()
                                                        .required(),
                                                })
                                            )
                                            .required(),
                                        smallTitle: yup.string(),
                                        title: yup.string(),
                                        subtitle: yup.string(),
                                        description: yup.string(),
                                    })
                                )
                                .required(),
                            slider: yup.array(
                                yup.object().shape({
                                    mediaType: yup.string().required(),
                                    image: yup
                                        .mixed()
                                        .test(
                                            "file-type",
                                            "Utilize ficheiros do tipo jpg ou png",
                                            CHECK_IF_FILE_IS_IMAGE
                                        ),
                                    video: yup
                                        .mixed()
                                        .when("mediaType", (mediaType) => {
                                            if (mediaType === "1") {
                                                return yup
                                                    .mixed()
                                                    .test(
                                                        "file-type",
                                                        "Utilize ficheiros de video apropriados",
                                                        CHECK_IF_FILE_IS_VIDEO
                                                    );
                                            }
                                        }),
                                    smallTitle: yup.string(),
                                    title: yup.string(),
                                    subtitle: yup.string(),
                                    description: yup.string(),
                                })
                            ),
                            mentors: yup.array().required(),
                            metatagsDescription: yup.string(),
                            metatagsTitle: yup.string(),
                            metatagsImage: yup
                                .mixed()
                                .test(
                                    "file-type",
                                    "Utilize ficheiros do tipo jpg ou png",
                                    CHECK_IF_FILE_IS_IMAGE
                                ),
                            contactEmail: yup.string(),
                        })}
                        onSubmit={(values) => {
                            updateData({
                                name: values.name,
                                active: values.active,
                                startDate: values.startDate,
                                endDate: values.endDate,
                                registrationDate: values.registrationDate,
                                regulation: values.regulation,
                                mentors: values.mentors.map(
                                    (item) => item.value
                                ),
                                prizes: values.prizes || [],
                                slider: values.slider || [],
                                legal: values.legal,
                                metatags: {
                                    image: values.metatagsImage,
                                    description: values.metatagsDescription,
                                    title: values.metatagsTitle,
                                },
                                contactEmail: values.contactEmail,
                            });
                        }}
                    >
                        {({ values, errors, handleSubmit }) => {
                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                >
                                    <Components.Portlet
                                        icon="Information"
                                        title="Período"
                                        anchor="general"
                                    >
                                        <Components.FormGroup>
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    error={errors.name}
                                                    Component={
                                                        Components.TextInput
                                                    }
                                                    name="name"
                                                    label="Name"
                                                    placeholder="name"
                                                />
                                            </Components.Block>
                                            <Components.Input
                                                error={errors.active}
                                                Component={
                                                    Components.CheckInput
                                                }
                                                name="active"
                                                label="Activo"
                                            />
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    error={errors.contactEmail}
                                                    Component={
                                                        Components.TextInput
                                                    }
                                                    name="contactEmail"
                                                    label="Email de contacto"
                                                    placeholder="exemplo@mail.com"
                                                />
                                            </Components.Block>
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    error={errors.startDate}
                                                    Component={
                                                        Components.DateInput
                                                    }
                                                    name="startDate"
                                                    label="Data de início"
                                                />
                                            </Components.Block>
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    error={errors.endDate}
                                                    Component={
                                                        Components.DateInput
                                                    }
                                                    name="endDate"
                                                    label="Data de fim"
                                                />
                                            </Components.Block>
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    error={errors.endDate}
                                                    Component={Components.DateInput}
                                                    name="registrationDate"
                                                    label="Data limite para incrição"
                                                />
                                            </Components.Block>
                                        </Components.FormGroup>
                                    </Components.Portlet>
                                    <Components.Input
                                        Component={Components.AccordionContent}
                                        title="Regulation"
                                        name="regulation"
                                        anchor="regulation"
                                        errors={errors}
                                    />
                                    <Components.Input
                                        Component={Components.AccordionContent}
                                        title="Legal"
                                        name="legal"
                                        anchor="legal"
                                        errors={errors}
                                    />
                                    <Components.Input
                                        Component={
                                            Components.AccordionHomeSliderMedia
                                        }
                                        sourceValues={data.slider.map(
                                            (item) => {
                                                return {
                                                    mediaType: item.mediaType,
                                                    imageSource: item.image,
                                                    videoSource: item.video,
                                                };
                                            }
                                        )}
                                        name="slider"
                                        anchor="slider"
                                        errors={errors}
                                    />
                                    <Components.Input
                                        Component={AccordionPrizes}
                                        name="prizes"
                                        anchor="prizes"
                                        errors={errors}
                                    />
                                    <Components.Portlet
                                        icon="Contacts"
                                        title="Mentores"
                                        anchor="mentors"
                                    >
                                        <Components.Block width={420}>
                                            <Components.Input
                                                error={errors.mentors}
                                                Component={
                                                    Components.DropdownMultiInput
                                                }
                                                options={mentorsOptions}
                                                name="mentors"
                                                label="Mentores"
                                                placeholder="Mentores"
                                                description="Mentores para participar no concurso"
                                            />
                                        </Components.Block>
                                    </Components.Portlet>
                                    <Components.Portlet
                                        icon="Paper"
                                        title="Metatags"
                                        anchor="metatags"
                                    >
                                        <Components.FormGroup>
                                            <Components.Input
                                                error={errors.metatagsTitle}
                                                Component={Components.TextInput}
                                                name="metatagsTitle"
                                                label="Título"
                                                placeholder="Título para colocar em metatags"
                                            />
                                            <Components.Input
                                                Component={
                                                    Components.ImageInput
                                                }
                                                name="metatagsImage"
                                                label="Imagem"
                                                imageSource={
                                                    !!data &&
                                                    data.metatags.image
                                                }
                                                error={errors.metatagsImage}
                                            />
                                            <Components.Input
                                                error={
                                                    errors.metatagsDescription
                                                }
                                                Component={
                                                    Components.TextAreaInput
                                                }
                                                name="metatagsDescription"
                                                label="Descrição"
                                                placeholder="Descrição para colocar em metatags"
                                            />
                                        </Components.FormGroup>
                                    </Components.Portlet>
                                </form>
                            );
                        }}
                    </Components.Form>
                </>
            ) : (
                <Components.Block center middle>
                    <Components.Loading
                        color={MediStrapProperties.colors.brand100}
                    />
                </Components.Block>
            )}
            <Components.DeleteActionPanel
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                title="Eliminar"
                description="Esta ação irá eliminar o registo permanentemente "
                onClickDelete={() => deleteData({ id: data.id })}
            />
        </Components.ModalThatSlides>
    );
}

export default ModalUpdate;
