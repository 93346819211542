import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Components } from "../../../MediStrap";
import {
    CHECK_IF_FILE_IS_IMAGE,
    CHECK_IF_FILE_IS_VIDEO,
} from "../../../utils/commonData";
import {
    MUTATION_SHOWROOM_DELETE,
    MUTATION_SHOWROOM_UPDATE,
} from "../graphql/mutations";
import { QUERY_SHOWROOM } from "../graphql/queries";

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, dataId }) {
    const updateFormRef = useRef(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [data, setData] = useState(null);

    const [query] = useLazyQuery(QUERY_SHOWROOM, {
        onError: () => {},
        onCompleted: (result) => {
            if (result && result.data) {
                setData(result.data);
            }
        },
    });

    const request = (params = {}) => {
        const { id } = params;

        query({
            variables: {
                id,
            },
        });
    };

    useEffect(() => {
        if (!!dataId) {
            request({ id: dataId });
        }
    }, [dataId]);

    const [mutationDataUpdate, { loading: loadingDataUpdate }] = useMutation(
        MUTATION_SHOWROOM_UPDATE,
        {
            onError: () => {},
            onCompleted: (result) => {
                if (result && result.data) {
                    onUpdate(result.data);
                    onRequestClose();
                }
            },
        }
    );

    const updateData = (params = {}) => {
        const {
            name,
            capacity,
            rooms,
            foodHall,
            imageThumbnail,
            location,
            latitude,
            longitude,
            socialNetworks,
            media,
            content,
            metatags,
        } = params;

        mutationDataUpdate({
            variables: {
                id: data.id,
                name,
                capacity,
                rooms,
                foodHall,
                imageThumbnail,
                location,
                latitude,
                longitude,
                socialNetworks,
                media,
                content,
                metatags,
            },
        });
    };

    const [mutationDataDelete, { loading: loadingDataDelete }] = useMutation(
        MUTATION_SHOWROOM_DELETE,
        {
            onError: () => {},
            onCompleted: (result) => {
                if (result && result.data) {
                    onDelete();
                    onRequestClose();
                }
            },
        }
    );

    const deleteData = () => {
        mutationDataDelete({
            variables: {
                id: data.id,
            },
        });
    };

    useEffect(() => {
        if (!isOpen) {
            setData(null);
        }
    }, [isOpen]);

    return (
        <Components.ModalThatSlides
            id="atualizar"
            title="Atualizar"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button
                            loading={loadingDataDelete}
                            size="square"
                            icon="Trash"
                            color="red"
                            onClick={() => setIsDeleteModalOpen(true)}
                        />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Button
                            type="submit"
                            loading={loadingDataUpdate}
                            label="Atualizar"
                            color="brand"
                            onClick={() => updateFormRef.current.handleSubmit()}
                        />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Geral", anchor: "general" },
                { icon: "Cicle", title: "Especificações", anchor: "specs" },
                { icon: "Cicle", title: "Imagens", anchor: "images" },
                { icon: "Angle_down", title: "Location", anchor: "location" },
                { icon: "Contacts", title: "Social media", anchor: "social" },
                { icon: "Anexo", title: "Media", anchor: "media" },
                { icon: "Notes", title: "Conteudo", anchor: "content" },
                {
                    icon: "Paper",
                    title: "Metatags",
                    anchor: "metatags",
                },
            ]}
        >
            {!!data && (
                <Components.Form
                    innerRef={updateFormRef}
                    initialValues={{
                        name: data.name,
                        socialNetworks: data.socialNetworks,
                        media: data.media.map((item) => {
                            return {
                                mediaType: item.mediaType,
                                imageSource: item.image,
                                videoSource: item.video,
                            };
                        }),
                        content: data.content,
                        capacity: data.capacity,
                        rooms: data.rooms,
                        foodHall: data.foodHall,
                        location: data.location,
                        latitude: data.latitude,
                        longitude: data.longitude,
                        metatagsDescription:
                            !!data.metatags && data.metatags.description,
                        metatagsTitle: !!data.metatags && data.metatags.title,
                    }}
                    schema={yup.object().shape({
                        name: yup.string().required(),
                        imageThumbnail: yup
                            .mixed()
                            .test(
                                "file-type",
                                "Utilize ficheiros do tipo jpg ou png",
                                CHECK_IF_FILE_IS_IMAGE
                            ),
                        capacity: yup.number().integer().required(),
                        rooms: yup.number().integer().required(),
                        foodHall: yup.string().required(),
                        location: yup.string().required(),
                        latitude: yup.string().required(),
                        longitude: yup.string().required(),
                        socialNetworks: yup.object(),
                        media: yup
                            .array(
                                yup.object().shape({
                                    mediaType: yup.string().required(),
                                    image: yup
                                        .mixed()
                                        .test(
                                            "file-type",
                                            "Utilize ficheiros do tipo jpg ou png",
                                            CHECK_IF_FILE_IS_IMAGE
                                        ),
                                    video: yup
                                        .mixed()
                                        .when("mediaType", (mediaType) => {
                                            if (mediaType === "1") {
                                                return yup
                                                    .mixed()
                                                    .test(
                                                        "file-type",
                                                        "Utilize ficheiros do tipo jpg ou png",
                                                        CHECK_IF_FILE_IS_VIDEO
                                                    );
                                            }
                                        }),
                                })
                            )
                            .required(),
                        content: yup.array(
                            yup.object().shape({
                                title: yup.string().required(),
                                text: yup.string().required(),
                            })
                        ),
                        metatagsDescription: yup.string(),
                        metatagsTitle: yup.string(),
                        metatagsImage: yup
                            .mixed()
                            .test(
                                "file-type",
                                "Utilize ficheiros do tipo jpg ou png",
                                CHECK_IF_FILE_IS_IMAGE
                            ),
                    })}
                    onSubmit={(values) => {
                        updateData({
                            name: values.name,
                            capacity: parseInt(values.capacity),
                            rooms: parseInt(values.rooms),
                            foodHall: values.foodHall,
                            imageThumbnail:
                                typeof values.imageThumbnail !== "string"
                                    ? values.imageThumbnail
                                    : undefined,
                            location: values.location,
                            latitude: values.latitude,
                            longitude: values.longitude,
                            socialNetworks: values.socialNetworks || {},
                            media: values.media || [],
                            content: values.content || [],
                            metatags: {
                                image: values.metatagsImage,
                                description: values.metatagsDescription,
                                title: values.metatagsTitle,
                            },
                        });
                    }}
                >
                    {({ values, errors, handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Components.Portlet
                                    icon="Information"
                                    title="Geral"
                                    anchor="general"
                                >
                                    <Components.FormGroup>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                error={errors.name}
                                                Component={Components.TextInput}
                                                name="name"
                                                label="Nome"
                                                placeholder="Nome"
                                            />
                                        </Components.Block>
                                    </Components.FormGroup>
                                </Components.Portlet>
                                <Components.Portlet
                                    icon="Cicle"
                                    title="Especificações"
                                    anchor="specs"
                                >
                                    <Components.FormGroup>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                Component={
                                                    Components.QuantityInput
                                                }
                                                name="capacity"
                                                label="Capacidade"
                                                error={errors.capacity}
                                            />
                                        </Components.Block>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                Component={
                                                    Components.QuantityInput
                                                }
                                                name="rooms"
                                                label="Número de salas"
                                                error={errors.rooms}
                                            />
                                        </Components.Block>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                error={errors.foodHall}
                                                Component={Components.TextInput}
                                                name="foodHall"
                                                label="Área de alimentação"
                                                placeholder="Área de alimentação"
                                            />
                                        </Components.Block>
                                    </Components.FormGroup>
                                </Components.Portlet>
                                <Components.Portlet
                                    icon="Cicle"
                                    title="Imagens"
                                    anchor="images"
                                >
                                    <Components.FormGroup>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                Component={
                                                    Components.ImageInput
                                                }
                                                imageSource={
                                                    data.imageThumbnail
                                                }
                                                name="imageThumbnail"
                                                label="Imagem thumbnail"
                                                error={errors.imageThumbnail}
                                            />
                                        </Components.Block>
                                    </Components.FormGroup>
                                </Components.Portlet>
                                <Components.Portlet
                                    icon="Angle_down"
                                    title="Location"
                                    anchor="location"
                                >
                                    <Components.FormGroup>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                error={errors.location}
                                                Component={Components.TextInput}
                                                name="location"
                                                label="Location"
                                                placeholder="Location"
                                            />
                                        </Components.Block>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                error={errors.latitude}
                                                Component={Components.TextInput}
                                                name="latitude"
                                                label="Latitude"
                                                placeholder="Latitude"
                                            />
                                        </Components.Block>
                                        <Components.Block width={420}>
                                            <Components.Input
                                                error={errors.longitude}
                                                Component={Components.TextInput}
                                                name="longitude"
                                                label="Longitude"
                                                placeholder="Longitude"
                                            />
                                        </Components.Block>
                                    </Components.FormGroup>
                                </Components.Portlet>
                                <Components.Portlet
                                    icon="Contacts"
                                    title="Social"
                                    anchor="social"
                                >
                                    <Components.SocialMediaInput
                                        errors={errors}
                                    />
                                </Components.Portlet>
                                <Components.Input
                                    Component={Components.AccordionMedia}
                                    sourceValues={data.media.map((item) => {
                                        return {
                                            mediaType: item.mediaType,
                                            imageSource: item.image,
                                            videoSource: item.video,
                                        };
                                    })}
                                    name="media"
                                    anchor="media"
                                    errors={errors}
                                />
                                <Components.Input
                                    Component={Components.AccordionContent}
                                    name="content"
                                    anchor="content"
                                    errors={errors}
                                />
                                <Components.Portlet
                                    icon="Paper"
                                    title="Metatags"
                                    anchor="metatags"
                                >
                                    <Components.FormGroup>
                                        <Components.Input
                                            error={errors.metatagsTitle}
                                            Component={Components.TextInput}
                                            name="metatagsTitle"
                                            label="Título"
                                            placeholder="Título para colocar em metatags"
                                        />
                                        <Components.Input
                                            Component={Components.ImageInput}
                                            name="metatagsImage"
                                            label="Imagem"
                                            imageSource={
                                                !!data && data.metatags.image
                                            }
                                            error={errors.metatagsImage}
                                        />
                                        <Components.Input
                                            error={errors.metatagsDescription}
                                            Component={Components.TextAreaInput}
                                            name="metatagsDescription"
                                            label="Descrição"
                                            placeholder="Descrição para colocar em metatags"
                                        />
                                    </Components.FormGroup>
                                </Components.Portlet>
                            </form>
                        );
                    }}
                </Components.Form>
            )}
            <Components.DeleteActionPanel
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                title="Eliminar"
                description="Esta ação irá eliminar o registo permanentemente "
                onClickDelete={() => deleteData({ id: data.id })}
            />
        </Components.ModalThatSlides>
    );
}

export default ModalUpdate;
