import React from "react";
import { Components } from "../../../MediStrap";

function AccordionPrizes({ name, value = [], anchor, errors }) {
    return (
        <Components.FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <Components.Portlet
                        anchor={anchor}
                        noInnerPadding
                        icon="Paper"
                        title="Prémios"
                        actions={
                            <Components.Button
                                type="button"
                                label="Adicionar prémio"
                                color="brand"
                                size="small"
                                onClick={() =>
                                    arrayHelpers.insert(value.length + 1, { items: []})
                                }
                            />
                        }
                    >
                        {value.map((item, index) => {
                            return (
                                <Components.AccordionItem
                                    key={index}
                                    index={index}
                                    name={name}
                                    title={"Prémio" + " #" + (index + 1)}
                                >
                                    <Components.FormGroup>
                                        <Components.Input
                                            Component={Components.TextInput}
                                            name={`${name}[${index}].place`}
                                            error={
                                                errors[
                                                    `${name}[${index}].place`
                                                ]
                                            }
                                            label="Lugar"
                                        />
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.FieldArray
                                            name={`${name}[${index}].items`}
                                            render={(itemArrayHelpers) => {
                                                return (
                                                    <Components.Portlet
                                                        noInnerPadding
                                                        icon="Paper"
                                                        title="Item"
                                                        actions={
                                                            <Components.Button
                                                                type="button"
                                                                label="Adicionar item"
                                                                color="brand"
                                                                size="small"
                                                                onClick={() =>
                                                                    itemArrayHelpers.insert(
                                                                        item.items.length +
                                                                            1,
                                                                        {
                                                                            overline: false,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    >
                                                        {item.items.map(
                                                            (
                                                                item,
                                                                itemIndex
                                                            ) => {
                                                                return (
                                                                    <Components.AccordionItem
                                                                        key={
                                                                            itemIndex
                                                                        }
                                                                        index={
                                                                            itemIndex
                                                                        }
                                                                        name={
                                                                            name
                                                                        }
                                                                        title={
                                                                            "Item" +
                                                                            " #" +
                                                                            (itemIndex +
                                                                                1)
                                                                        }
                                                                    >
                                                                        <Components.FormGroup title="Item">
                                                                            <Components.Input
                                                                                Component={
                                                                                    Components.TextInput
                                                                                }
                                                                                name={`${name}[${index}].items.[${itemIndex}].text`}
                                                                                error={
                                                                                    errors[
                                                                                        `${name}[${index}].items.[${itemIndex}].text`
                                                                                    ]
                                                                                }
                                                                                label="Texto"
                                                                            />
                                                                            <Components.Input
                                                                                Component={
                                                                                    Components.CheckInput
                                                                                }
                                                                                name={`${name}[${index}].items.[${itemIndex}].overline`}
                                                                                label="Riscado"
                                                                            />
                                                                        </Components.FormGroup>
                                                                        <Components.FormGroup>
                                                                            <Components.DeleteLine
                                                                                title="Eliminar media"
                                                                                description="Esta ação irá eliminar os dados relativos ao item permanentemente"
                                                                                onClickDelete={() => {
                                                                                    itemArrayHelpers.remove(
                                                                                        itemIndex
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Components.FormGroup>
                                                                    </Components.AccordionItem>
                                                                );
                                                            }
                                                        )}
                                                    </Components.Portlet>
                                                );
                                            }}
                                        />
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar media"
                                            description="Esta ação irá eliminar os dados relativos ao prémio permanentemente"
                                            onClickDelete={() => {
                                                arrayHelpers.remove(index);
                                            }}
                                        />
                                    </Components.FormGroup>
                                </Components.AccordionItem>
                            );
                        })}
                    </Components.Portlet>
                );
            }}
        />
    );
}

export default AccordionPrizes;
