import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Components, MediStrap } from "../../../MediStrap";
import {
    CHECK_IF_FILE_IS_IMAGE,
    CHECK_IF_FILE_IS_VIDEO,
} from "../../../utils/commonData";
import {
    MUTATION_MENTOR_DELETE,
    MUTATION_MENTOR_UPDATE,
} from "../graphql/mutations";
import { QUERY_MENTOR } from "../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({
    onRequestClose,
    onUpdate,
    onDelete,
    isOpen,
    genresOptions,
    dataId,
}) {
    const updateFormRef = useRef(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [data, setData] = useState(null);

    const [query] = useLazyQuery(QUERY_MENTOR, {
        onError: () => {},
        onCompleted: (result) => {
            if (result && result.data) {
                setData(result.data);
            }
        },
    });

    const request = (params = {}) => {
        const { id } = params;

        query({
            variables: {
                id,
            },
        });
    };

    useEffect(() => {
        if (!!dataId) {
            request({ id: dataId });
        }
    }, [dataId]);

    const [mutationDataUpdate, { loading: loadingDataUpdate }] = useMutation(
        MUTATION_MENTOR_UPDATE,
        {
            onError: () => {},
            onCompleted: (result) => {
                if (result && result.data) {
                    onUpdate(result.data);
                    onRequestClose();
                }
            },
        }
    );

    const updateData = (params = {}) => {
        const {
            name,
            imageThumbnail,
            genres,
            socialNetworks,
            media,
            content,
            description,
            metatags,
        } = params;

        mutationDataUpdate({
            variables: {
                id: data.id,
                name,
                imageThumbnail,
                genres,
                socialNetworks,
                media,
                content,
                description,
                metatags,
            },
        });
    };

    const [mutationDataDelete, { loading: loadingDataDelete }] = useMutation(
        MUTATION_MENTOR_DELETE,
        {
            onError: () => {},
            onCompleted: (result) => {
                if (result && result.data) {
                    onDelete();
                    onRequestClose();
                }
            },
        }
    );

    const deleteData = () => {
        mutationDataDelete({
            variables: {
                id: data.id,
            },
        });
    };

    useEffect(() => {
        if (!isOpen) {
            setData(null);
        }
    }, [isOpen]);

    return (
        <Components.ModalThatSlides
            title="Editar"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            width="100%"
            actions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button
                            loading={loadingDataDelete}
                            size="square"
                            icon="Trash"
                            color="red"
                            onClick={() => setIsDeleteModalOpen(true)}
                        />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Button
                            type="submit"
                            loading={loadingDataUpdate}
                            label="Editar"
                            color="brand"
                            onClick={() => updateFormRef.current.handleSubmit()}
                        />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Geral", anchor: "general" },
                { icon: "Cicle", title: "Imagens", anchor: "images" },
                {
                    icon: "Contacts",
                    title: "Social media",
                    anchor: "socialMedia",
                },
                { icon: "Anexo", title: "Media", anchor: "media" },
                { icon: "Notes", title: "Conteudo", anchor: "content" },
                {
                    icon: "Paper",
                    title: "Metatags",
                    anchor: "metatags",
                },
            ]}
        >
            {!!data ? (
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            genres: data.genres.map((item) => {
                                return { value: item.id, label: item.name };
                            }),
                            name: data.name,
                            socialNetworks: data.socialNetworks,
                            media: data.media.map((item) => {
                                return {
                                    mediaType: item.mediaType,
                                    imageSource: item.image,
                                    videoSource: item.video,
                                };
                            }),
                            content: data.content,
                            description: data.description,
                            metatagsDescription: !!data.metatags && data.metatags.description,
                            metatagsTitle: !!data.metatags && data.metatags.title,
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required(),
                            imageThumbnail: yup
                                .mixed()
                                .test(
                                    "file-type",
                                    "Utilize ficheiros do tipo jpg ou png",
                                    CHECK_IF_FILE_IS_IMAGE
                                ),
                            genres: yup.array().required(),
                            socialNetworks: yup.object(),
                            media: yup.array(
                                yup.object().shape({
                                    mediaType: yup.string().required(),
                                    image: yup
                                        .mixed()
                                        .test(
                                            "file-type",
                                            "Utilize ficheiros do tipo jpg ou png",
                                            CHECK_IF_FILE_IS_IMAGE
                                        ),
                                    video: yup
                                        .mixed()
                                        .when("mediaType", (mediaType) => {
                                            if (mediaType === "1") {
                                                return yup
                                                    .mixed()
                                                    .test(
                                                        "file-type",
                                                        "Utilize ficheiros de video apropriados",
                                                        CHECK_IF_FILE_IS_VIDEO
                                                    );
                                            }
                                        }),
                                })
                            ),
                            content: yup.array(
                                yup.object().shape({
                                    title: yup.string().required(),
                                    text: yup.string().required(),
                                })
                            ),
                            description: yup.string(),
                            metatagsDescription: yup.string(),
                            metatagsImage: yup
                                .mixed()
                                .test(
                                    "file-type",
                                    "Utilize ficheiros do tipo jpg ou png",
                                    CHECK_IF_FILE_IS_IMAGE
                                ),
                        })}
                        onSubmit={(values) => {
                            updateData({
                                name: values.name,
                                imageThumbnail: values.imageThumbnail,
                                genres: values.genres.map((item) => item.value),
                                socialNetworks: values.socialNetworks || {},
                                media: values.media
                                    ? values.media.map((item, index) => {
                                          return {
                                              mediaType: item.mediaType,
                                              imageSource: !!item.imageSource
                                                  ? item.imageSource
                                                  : `image_${index}`,
                                              image: item.image,
                                              video:
                                                  item.mediaType === "1"
                                                      ? item.video
                                                      : undefined,
                                              videoSource: item.videoSource,
                                          };
                                      })
                                    : [],
                                content: values.content || [],
                                description: values.description,
                                metatags: {
                                    image: values.metatagsImage,
                                    description: values.metatagsDescription,
                                    title: values.metatagsTitle
                                },
                            });
                        }}
                    >
                        {({ values, errors, handleSubmit }) => {
                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                >
                                    <Components.Portlet
                                        icon="Information"
                                        title="Geral"
                                        anchor="general"
                                    >
                                        <Components.FormGroup>
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    error={errors.name}
                                                    Component={
                                                        Components.TextInput
                                                    }
                                                    name="name"
                                                    label="Nome"
                                                    placeholder="Nome do mentor"
                                                />
                                            </Components.Block>
                                            <Components.Block width={600}>
                                                <Components.Input
                                                    error={errors.description}
                                                    Component={
                                                        Components.TextInput
                                                    }
                                                    name="description"
                                                    label="Descrição"
                                                    placeholder="Descrição"
                                                />
                                            </Components.Block>
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    error={errors.genres}
                                                    Component={
                                                        Components.DropdownMultiInput
                                                    }
                                                    options={genresOptions}
                                                    name="genres"
                                                    label="Géneros"
                                                    placeholder="Géneros"
                                                />
                                            </Components.Block>
                                        </Components.FormGroup>
                                    </Components.Portlet>
                                    <Components.Portlet
                                        icon="Information"
                                        title="Imagens"
                                        anchor="images"
                                    >
                                        <Components.FormGroup>
                                            <Components.Block width={420}>
                                                <Components.Input
                                                    Component={
                                                        Components.ImageInput
                                                    }
                                                    imageSource={
                                                        data.imageThumbnail
                                                    }
                                                    name="imageThumbnail"
                                                    label="Imagem thumbnail"
                                                    error={
                                                        errors.imageThumbnail
                                                    }
                                                />
                                            </Components.Block>
                                        </Components.FormGroup>
                                    </Components.Portlet>
                                    <Components.Portlet
                                        icon="Information"
                                        title="Social media"
                                        anchor="socialMedia"
                                    >
                                        <Components.SocialMediaInput
                                            errors={errors}
                                        />
                                    </Components.Portlet>
                                    <Components.Input
                                        Component={Components.AccordionMedia}
                                        sourceValues={data.media.map((item) => {
                                            return {
                                                mediaType: item.mediaType,
                                                imageSource: item.image,
                                                videoSource: item.video,
                                            };
                                        })}
                                        name="media"
                                        anchor="media"
                                        errors={errors}
                                    />
                                    <Components.Input
                                        Component={Components.AccordionContent}
                                        name="content"
                                        anchor="media"
                                        errors={errors}
                                    />
                                    <Components.Portlet
                                        icon="Paper"
                                        title="Metatags"
                                        anchor="metatags"
                                    >
                                        <Components.FormGroup>
                                            <Components.Input
                                                error={errors.metatagsTitle}
                                                Component={Components.TextInput}
                                                name="metatagsTitle"
                                                label="Título"
                                                placeholder="Título para colocar em metatags"
                                            />
                                            <Components.Input
                                                Component={
                                                    Components.ImageInput
                                                }
                                                name="metatagsImage"
                                                label="Imagem"
                                                imageSource={
                                                    !!data &&
                                                    data.metatags.image
                                                }
                                                error={errors.metatagsImage}
                                            />
                                            <Components.Input
                                                error={
                                                    errors.metatagsDescription
                                                }
                                                Component={
                                                    Components.TextAreaInput
                                                }
                                                name="metatagsDescription"
                                                label="Descrição"
                                                placeholder="Descrição para colocar em metatags"
                                            />
                                        </Components.FormGroup>
                                    </Components.Portlet>
                                </form>
                            );
                        }}
                    </Components.Form>
                </>
            ) : (
                <Components.Block center middle>
                    <Components.Loading
                        color={MediStrapProperties.colors.brand100}
                    />
                </Components.Block>
            )}
            <Components.DeleteActionPanel
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                title="Eliminar"
                description="Esta ação irá eliminar o registo permanentemente "
                onClickDelete={() => deleteData({ id: data.id })}
            />
        </Components.ModalThatSlides>
    );
}

export default ModalUpdate;
