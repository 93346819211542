import gql from "graphql-tag";

export const QUERY_GENRES = gql`
    query genres($perPage: Int, $currentPage: Int, $search: String, $orderBy: GenresOrderBy) {
        data: genres(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy) {
            rows {
                id
                name
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_GENRE = gql`
    query genre($id: ID!) {
        data: genre(id: $id) {
            id
            name
        }
    }
`;

export const QUERY_MENTORS = gql`
    query mentors($perPage: Int, $currentPage: Int, $search: String, $filters: InputMentorsFilters, $orderBy: MentorsOrderBy) {
        data: mentors(perPage: $perPage, currentPage: $currentPage, search: $search, filters: $filters, orderBy: $orderBy) {
            rows {
                id
                name
                imageThumbnail
                genres {
                    id
                    name
                }
                socialNetworks
                media
                content
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
                orderBy
            }
        }
    }
`;

export const QUERY_MENTOR = gql`
    query mentor($id: ID!) {
        data: mentor(id: $id) {
            id
            name
            imageThumbnail
            genres {
                id
                name
            }
            socialNetworks
            media
            content
            description
            metatags
        }
    }
`;