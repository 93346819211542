import gql from "graphql-tag";

export const QUERY_SHOWROOMS = gql`
    query showrooms($perPage: Int, $currentPage: Int, $search: String, $orderBy: ShowroomsOrderBy) {
        data: showrooms(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
                imageThumbnail
                location
                latitude
                longitude
                socialNetworks
                media
                content
                capacity
                rooms
                foodHall
                bands {
                    id
                    name
                }
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_SHOWROOM = gql`
    query showroom($id: ID!) {
        data: showroom(id: $id) {
            id
            name
            imageThumbnail
            location
            latitude
            longitude
            socialNetworks
            media
            content
            capacity
            rooms
            foodHall
            bands {
                id
                name
            }
            metatags
        }
    }
`;