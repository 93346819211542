import gql from "graphql-tag";

export const QUERY_USERS = gql`
    query users($perPage: Int, $currentPage: Int, $search: String, $orderBy: UsersOrderBy) {
        users(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy){
            rows {
                id
                name
                email
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
            }
        }
    }
`;

export const QUERY_USER = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            name
            email
        }
    }
`;