import gql from "graphql-tag";

export const QUERY_CONTESTS = gql`
    query Contests($perPage: Int, $currentPage: Int, $search: String, $orderBy: ContestsOrderBy) {
        data: contests(perPage: $perPage, currentPage: $currentPage, search: $search, orderBy: $orderBy) {
            rows {
                id
                name
                startDate
                endDate
                registrationDate
            }
            pagination {
                total
                perPage
                lastPage
                currentPage
                hasPreviousPage
                hasNextPage
                orderBy
            }
        }
    }
`;

export const QUERY_CONTEST = gql`
    query Contest($id: ID!) {
        data: contest(id: $id) {
            id
            name
            active
            startDate
            endDate
            registrationDate
            regulation
            slider
            mentors {
                id
                name
                imageThumbnail
                genres {
                        name
                        id
                }
                socialNetworks
                media
                content
            }
            prizes {
                items {
                    text
                    overline
                }
                place
            }
            legal
            metatags
            contactEmail
        }
    }
`;