import React, { useEffect, useRef, useState } from "react";
import styled, { css } from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from '../../Block';
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function DropDownInput(
    {
        label,
        description,
        error,
        disabled,
        placeholder,
        onChangeValue,
        options = [],
        value,
        monocolor
    }
) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    const handleClickOutside = (event) => {
        if (ref && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const onClick = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    }

    return (
        <Block ref={ref} width="100%">
            {!!label && <Text size={12} height={16} mb={8} color={MediStrapProperties.colors.grey100}>{label}</Text>}
            <Block style={{ position: "relative" }}>
                {(!error) && <AngleWrapper disabled={disabled} onClick={onClick}>{isOpen ? <span className="icon-Angle_up" /> : <span className="icon-Angle_down" />}</AngleWrapper>}
                {(!!error) && <StatusWrapper><span className="icon-Error" /></StatusWrapper>}
                <Input
                    error={error}
                    disabled={disabled}
                    onClick={onClick}
                    monocolor={monocolor}
                >
                    {(!value && !!placeholder) && <Text size={14} height={24} color={MediStrapProperties.colors.grey50}>{placeholder}</Text>}
                    {(!!value) && <Text size={14} height={24} color={disabled ? MediStrapProperties.colors.grey20 : !!monocolor ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.grey100}>{value.label}</Text>}
                </Input>
                {isOpen && <Options>
                    {options.map((option, index) => {
                        const selected = !!value && value.value === option.value;
                        return (
                            <Option key={index} onClick={() => { onChangeValue(option); setIsOpen(false); }}>
                                <Text size={14} height={24} color={selected ? MediStrapProperties.colors.grey100 : MediStrapProperties.colors.grey50}>{option.label}</Text>
                                {(selected) && <Checked className="icon-Check" />}
                            </Option>
                        );
                    })}
                </Options>}
            </Block>
            {(() => {
                let color = null;
                let text = null;
                if (!!error) { text = typeof error === "string" ? error : null; color = MediStrapProperties.colors.alert100; }
                else if (!!description) { text = description; color = MediStrapProperties.colors.grey50; }

                if (!!text) {
                    return (
                        <Block mt={8}>
                            <Text
                                size={11}
                                height={16}
                                color={color}
                            >
                                {error || description}
                            </Text>
                        </Block>
                    );
                }
            })()}
        </Block>
    );
}

export default React.memo(DropDownInput);

const Input = styled.div`
    background-color: ${({ monocolor }) => !!monocolor ? MediStrapProperties.colors.grey5 : MediStrapProperties.colors.white100};
    height: 48px;
    color: ${MediStrapProperties.colors.grey100};
    font-size: 14px;
    line-height: 24px;
    font-family: "AvertaSemiBold";
    border: 0;
    padding: 12px 40px 12px 16px;
    border: 1px solid ${({ monocolor }) => !!monocolor ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.grey20};
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    user-select: none;
    
    ${({ disabled }) => !disabled && css`
        cursor: pointer;
    `}

    :hover {
        border-color: ${({ error }) => {
        if (!!error) {
            return MediStrapProperties.colors.alert100;
        }

        return ({ monocolor }) => !!monocolor ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.grey60;
    }};
        background: ${({ monocolor }) => !!monocolor && MediStrapProperties.colors.brand5};
    }

    ${({ disabled }) => !!disabled && css`
        color: ${MediStrapProperties.colors.grey20};
        background-color: ${MediStrapProperties.colors.grey5};
        border-color: ${MediStrapProperties.colors.grey20};
    `}

    ${({ error }) => !!error && css`
        border-color:  ${MediStrapProperties.colors.alert100};
    `}

    overflow: hidden;

    span {
        display: inline-block; 
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
        width: calc(100%);
    }
`;

const AngleWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;


    ${({ disabled }) => !disabled && css`
        cursor: pointer;
    `}
`;

const StatusWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;

const Options = styled.div`
    position: absolute;
    top: 56px;
    left: 0px;
    right: 0px;
    max-height: 128px;
    background-color: ${MediStrapProperties.colors.white100};
    border: 1px solid ${MediStrapProperties.colors.brand100};
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    z-index: 2;
    overflow-y: auto;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
    }
`;

const Checked = styled.span`
    position: absolute;
    top: 12px;
    right: 12px;
    ::before {
        color: ${MediStrapProperties.colors.brand100};
    }
`;

const Option = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    width: 100%;
    padding: 0 40px 0 16px;

    :hover {
        background-color: ${MediStrapProperties.colors.brand100};

        span {
            color: ${MediStrapProperties.colors.white100} !important;
        }

        ${Checked}::before {
            color: ${MediStrapProperties.colors.white100};
        }
    }
`;
