const colors = {
    white: "white",
    black: "black",
    greyDarker: "#222527",
    greyDark: "#35383B",
    grey: "#5D6368",
    greyAlmostNormal: "#899098",
    greyLight: "#B2B6B9",
    greyLightest: "#D8DADC",
    greyAlmostWhite: "#EBECED",
    greyWhite: "#F9FAFA",
    greyNotWhite: "#F5F5F5",


    red: "#FF4D15",
    redDark: "#DA3400",
    orange: "#FFA337",

    green: "#368D67",
    greenDark: "#236146",
    greenLight: "#5BB990"
}

export default colors;