import React, { useState } from "react";
import AccordionItem from "../../AccordionItem";
import Block from "../../Block";
import Button from "../../Button";
import DeleteLine from "../../DeleteLine";
import FieldArray from "../../FieldArray";
import FormGroup from "../../FormGroup";
import Input from "../../Input";
import ImageInput from "../../Inputs/ImageInput";
import RadioInput from "../../Inputs/RadioInput";
import VideoInput from "../../Inputs/VideoInput";
import Portlet from "../../Portlet";
import TextAreaInput from "../TextAreaInput";
import TextInput from "../TextInput";

function AccordionHomeSliderMedia({
    name,
    value = [],
    sourceValues,
    anchor,
    errors,
}) {
    const [sourceValuesState, setSourceValuesState] = useState(sourceValues);

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <Portlet
                        anchor={anchor}
                        noInnerPadding
                        icon="Paper"
                        title="Media"
                        actions={
                            <Button
                                type="button"
                                label="Adicionar media"
                                color="brand"
                                size="small"
                                onClick={() =>
                                    arrayHelpers.insert(value.length + 1, {
                                        mediaType: "0",
                                    })
                                }
                            />
                        }
                    >
                        {value.map((item, index) => {
                            return (
                                <AccordionItem
                                    key={index}
                                    index={index}
                                    name={name}
                                    title={"Media" + " #" + (index + 1)}
                                >
                                    <FormGroup title="Media">
                                        <Block row>
                                            <Block mr={12}>
                                                <Input
                                                    Component={RadioInput}
                                                    radioValue="0"
                                                    name={`${name}[${index}].mediaType`}
                                                    label="Imagem"
                                                />
                                            </Block>
                                            <Block>
                                                <Input
                                                    Component={RadioInput}
                                                    radioValue="1"
                                                    name={`${name}[${index}].mediaType`}
                                                    label="Vídeo"
                                                />
                                            </Block>
                                        </Block>
                                        {!!item.mediaType &&
                                            item.mediaType === "1" ? (
                                            <FormGroup>
                                                <Block>
                                                    <Input
                                                        Component={ImageInput}
                                                        name={`${name}[${index}].image`}
                                                        label="Thumbnail do vídeo"
                                                        imageSource={
                                                            !!sourceValuesState &&
                                                            sourceValuesState.length >
                                                            index &&
                                                            sourceValuesState[
                                                                index
                                                            ].imageSource
                                                        }
                                                        error={
                                                            errors[
                                                            `${name}[${index}].image`
                                                            ]
                                                        }
                                                    />
                                                </Block>
                                                <Block>
                                                    <Input
                                                        Component={VideoInput}
                                                        name={`${name}[${index}].video`}
                                                        label="Vídeo"
                                                        videoSource={
                                                            !!sourceValuesState &&
                                                            sourceValuesState.length >
                                                            index &&
                                                            sourceValuesState[
                                                                index
                                                            ].videoSource
                                                        }
                                                        error={
                                                            errors[
                                                            `${name}[${index}].video`
                                                            ]
                                                        }
                                                    />
                                                </Block>
                                            </FormGroup>
                                        ) : (
                                            <Block>
                                                <Block>
                                                    <Input
                                                        Component={ImageInput}
                                                        name={`${name}[${index}].image`}
                                                        label="Imagem"
                                                        imageSource={
                                                            !!sourceValuesState &&
                                                            sourceValuesState.length >
                                                            index &&
                                                            sourceValuesState[
                                                                index
                                                            ].imageSource
                                                        }
                                                        error={
                                                            errors[
                                                            `${name}[${index}].image`
                                                            ]
                                                        }
                                                    />
                                                </Block>
                                            </Block>
                                        )}
                                    </FormGroup>
                                    <FormGroup title="Textos">
                                        <Block>
                                            <Input
                                                Component={TextInput}
                                                name={`${name}[${index}].smallTitle`}
                                                label="Título Pequeno"
                                                error={
                                                    errors[
                                                    `${name}[${index}].smallTitle`
                                                    ]
                                                }
                                            />
                                        </Block>
                                        <Block>
                                            <Input
                                                Component={TextInput}
                                                name={`${name}[${index}].title`}
                                                label="Título"
                                                error={
                                                    errors[
                                                    `${name}[${index}].title`
                                                    ]
                                                }
                                            />
                                        </Block>
                                        <Block>
                                            <Input
                                                Component={TextInput}
                                                name={`${name}[${index}].subtitle`}
                                                label="Subtítulo"
                                                error={
                                                    errors[
                                                    `${name}[${index}].subtitle`
                                                    ]
                                                }
                                            />
                                        </Block>
                                        <Block>
                                            <Input
                                                Component={TextAreaInput}
                                                name={`${name}[${index}].description`}
                                                label="Descrição"
                                                error={
                                                    errors[
                                                    `${name}[${index}].description`
                                                    ]
                                                }
                                            />
                                        </Block>
                                    </FormGroup>
                                    <FormGroup>
                                        <DeleteLine
                                            title="Eliminar media"
                                            description="Esta ação irá eliminar os dados relativos à media permanentemente"
                                            onClickDelete={() => {
                                                arrayHelpers.remove(index);
                                                !!sourceValuesState &&
                                                    setSourceValuesState(
                                                        sourceValuesState.filter(
                                                            (
                                                                _item,
                                                                itemIndex
                                                            ) =>
                                                                itemIndex !==
                                                                index
                                                        )
                                                    );
                                            }}
                                        />
                                    </FormGroup>
                                </AccordionItem>
                            );
                        })}
                    </Portlet>
                );
            }}
        />
    );
}

export default AccordionHomeSliderMedia;
