import { useMutation } from "@apollo/react-hooks";
import { useRef } from "react";
import * as yup from 'yup';
import { Components } from "../../../../MediStrap";
import { MUTATION_FAQ_SUBJECT_CREATE } from "../../graphql/mutations";

function ModalCreate({ onRequestClose, onCreate, isOpen }) {
    const createFormRef = useRef(null);

    const [mutationDataCreate, { loading: loadingDataCreate }] = useMutation(
        MUTATION_FAQ_SUBJECT_CREATE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    onCreate();
                    onRequestClose();
                }
            }
        }
    );

    const createData = (params = {}) => {
        const { name } = params;

        mutationDataCreate({
            variables: {
                name
            }
        });
    }

    return (
        <Components.Modal
            title="Criar"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingDataCreate} size="medium" label="Criar" color="brand" onClick={() => createFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            <Components.Form
                innerRef={createFormRef}
                schema={yup.object().shape({
                    name: yup.string().required()
                })}
                onSubmit={(values) => {
                    createData(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.FormGroup title="Geral">
                                <Components.Block width={420}>
                                    <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Nome" placeholder="Nome do tópico" />
                                </Components.Block>
                            </Components.FormGroup>
                        </form>
                    )
                }}
            </Components.Form>
        </Components.Modal>
    );
}

export default ModalCreate;
