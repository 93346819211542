import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../MediStrap";
import { MUTATION_FAQ_DELETE, MUTATION_FAQ_UPDATE } from "../graphql/mutations";
import { QUERY_FAQ } from "../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, faqSubjectsOptions, dataId }) {
    const updateFormRef = useRef(null);

    const [data, setData] = useState(null);

    const [query] = useLazyQuery(
        QUERY_FAQ,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    setData(result.data);
                }
            }
        }
    );

    const request = (params = {}) => {
        const { id } = params;

        query({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!dataId) {
            request({ id: dataId });
        }
    }, [dataId]);

    const [mutationDataUpdate, { loading: loadingDataUpdate }] = useMutation(
        MUTATION_FAQ_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    onUpdate(result.data);
                    onRequestClose();
                }
            }
        }
    );

    const updateData = (params = {}) => {
        const { question, answer, faqSubjects } = params;

        mutationDataUpdate({
            variables: {
                id: data.id,
                question,
                answer,
                subjects: faqSubjects
            }
        });
    }

    const [mutationDataDelete, { loading: loadingDataDelete }] = useMutation(
        MUTATION_FAQ_DELETE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    onDelete();
                    onRequestClose();
                }
            }
        }
    );

    const deleteData = () => {
        mutationDataDelete({
            variables: {
                id: data.id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setData(null);
        }
    }, [isOpen])

    return (
        <Components.Modal
            title="Editar"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block data center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingDataUpdate} size="medium" label="Editar" color="brand" onClick={() => updateFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            {!!data ?
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            question: data.question,
                            answer: data.answer,
                            faqSubjects: data.subjects.map((item) => { return { value: item.id, label: item.name } })
                        }}
                        schema={yup.object().shape({
                            question: yup.string(),
                            answer: yup.string(),
                            faqSubjects: yup.array()
                        })}
                        onSubmit={(values) => {
                            updateData({
                                faqSubjects: values.faqSubjects.map((item) => item.value),
                                question: values.question,
                                answer: values.answer
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.FormGroup title="Geral">
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.question} Component={Components.TextInput} name="question" label="Pergunta" placeholder="Pergunta" />
                                        </Components.Block>
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.answer} Component={Components.TextAreaInput} name="answer" label="Resposta" placeholder="Resposta" />
                                        </Components.Block>
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.faqSubjects} Component={Components.DropdownMultiInput} options={faqSubjectsOptions} name="faqSubjects" label="Tópicos" placeholder="Tópicos" description="Tópicos em que a pergunta se enquadra" />
                                        </Components.Block>
                                        <Components.DeleteLine
                                            title="Eliminar"
                                            description="Esta ação irá eliminar o registo permanentemente "
                                            onClickDelete={() => deleteData({ id: data.id })}
                                            deleteButtonLoading={loadingDataDelete}
                                        />
                                    </Components.FormGroup>
                                </form>
                            )
                        }}
                    </Components.Form>
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>
            }
        </Components.Modal >
    );
}

export default ModalUpdate;
