import React from "react";
import styled from 'styled-components';
import MediStrap from "../../MediStrap";
import Block from '../Block';
import Text from '../Text';

const MediStrapProperties = MediStrap.getProperties();

function Navigation(
    {
        perPage,
        currentPage,
        hasPreviousPage,
        hasNextPage,
        total,
        onClickPrevious,
        onClickNext
    }
) {
    return (
        <Wrapper row center space="between" color={MediStrapProperties.colors.white100} pl={16} pr={16}>
            <Icon className="icon-Angle_left" active={hasPreviousPage} onClick={() => hasPreviousPage && onClickPrevious()} />
            <Text size={14} height={24} color={MediStrapProperties.colors.grey100}>{currentPage * perPage - perPage + 1}-{hasNextPage ? currentPage * perPage : total}</Text>
            <Text size={14} height={24} color={MediStrapProperties.colors.grey50}>de</Text>
            <Text size={14} height={24} color={MediStrapProperties.colors.grey50}>{total}</Text>
            <Icon className="icon-Angle_right" active={hasNextPage} onClick={() => hasNextPage && onClickNext()} />
        </Wrapper>
    );
}

export default React.memo(Navigation);

const Icon = styled.span`
    color: ${MediStrapProperties.colors.grey60};
    cursor: ${({ active }) => active ? "pointer" : "no-drop"};
`;

const Wrapper = styled(Block)`
    width: 216px;
    height: 48px;
    position: relative;
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    border: 1px solid ${MediStrapProperties.colors.grey20};

    :hover {
        border: 1px solid ${MediStrapProperties.colors.grey60};
    }
`;