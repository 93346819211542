import React from "react";

export default React.forwardRef(function Block(
  {
    pointer,
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    flex,
    row,
    column,
    center,
    middle,
    left,
    right,
    top,
    bottom,
    card,
    color,
    space,
    wrap,
    br,
    width,
    height,
    style,
    children,
    ...props
  },
  ref
) {

  const blockStyles = [
    styles.main,
    pointer && { cursor: "pointer" },
    width && { width: width, minWidth: width },
    height && { height: height, minHeight: height },
    mt && { marginTop: mt },
    mb && { marginBottom: mb },
    ml && { marginLeft: ml },
    mr && { marginRight: mr },
    pt && { paddingTop: pt },
    pb && { paddingBottom: pb },
    pl && { paddingLeft: pl },
    pr && { paddingRight: pr },
    flex && { flex },
    flex === true && { flex: 1 },
    row && styles.row,
    column && styles.column,
    center && styles.center,
    middle && styles.middle,
    left && styles.left,
    right && styles.right,
    top && styles.top,
    bottom && styles.bottom,
    card && styles.card,
    space && { justifyContent: `space-${space}` },
    wrap && { flexWrap: "wrap" },
    color && { backgroundColor: color }, // custom backgroundColor
    br && { borderRadius: br }, // border radius
    style // rewrite predefined styles
  ];

  return (
    <div ref={ref} style={blockStyles.reduce((prev = {}, curr) => {
      if (!curr) return prev;
      return { ...prev, ...curr };
    })} {...props}>
      {children}
    </div>
  );
});

const styles = {
  main: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    flexDirection: "row"
  },
  column: {
    flexDirection: "column"
  },
  center: {
    alignItems: "center"
  },
  middle: {
    justifyContent: "center"
  },
  left: {
    justifyContent: "flex-start"
  },
  right: {
    justifyContent: "flex-end"
  },
  top: {
    justifyContent: "flex-start"
  },
  bottom: {
    justifyContent: "flex-end"
  }
};