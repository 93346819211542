import gql from "graphql-tag";

export const MUTATION_SHOWROOM_CREATE = gql`
    mutation showroomCreate(
        $name: String!
        $imageThumbnail: Upload!
        $location: String!
        $latitude: String!
        $longitude: String!
        $socialNetworks: JSON!
        $media: [InputMedia]!
        $content: JSON!
        $capacity: Int!
        $rooms: Int!
        $foodHall: String!
        $metatags: InputMetatags!
    ) {
        data: showroomCreate(
            name: $name
            imageThumbnail: $imageThumbnail
            location: $location
            latitude: $latitude
            longitude: $longitude
            socialNetworks: $socialNetworks
            media: $media
            content: $content
            capacity: $capacity
            rooms: $rooms
            foodHall: $foodHall
            metatags: $metatags
        ) {
            id
            name
            imageThumbnail
            location
            latitude
            longitude
            socialNetworks
            media
            content
            capacity
            rooms
            foodHall
            bands {
                id
                name
            }
            metatags
        }
    }
`;

export const MUTATION_SHOWROOM_UPDATE = gql`
    mutation showroomUpdate(
        $id: ID!
        $name: String
        $imageThumbnail: Upload
        $location: String
        $latitude: String
        $longitude: String
        $socialNetworks: JSON
        $media: [InputMediaUpdate]
        $content: JSON
        $capacity: Int
        $rooms: Int
        $foodHall: String
        $metatags: InputMetatags!
    ) {
        data: showroomUpdate(
            id: $id
            name: $name
            imageThumbnail: $imageThumbnail
            location: $location
            latitude: $latitude
            longitude: $longitude
            socialNetworks: $socialNetworks
            media: $media
            content: $content
            capacity: $capacity
            rooms: $rooms
            foodHall: $foodHall
            metatags: $metatags
        ) {
            id
            name
            imageThumbnail
            location
            latitude
            longitude
            socialNetworks
            media
            content
            capacity
            rooms
            foodHall
            bands {
                id
                name
            }
            metatags
        }
    }
`;

export const MUTATION_SHOWROOM_DELETE = gql`
    mutation showroomDelete($id: ID!) {
        data: showroomDelete(id: $id) {
            id
        }
    }
`;
