import React, { useRef, useState } from "react";
import MediStrap from "../../../MediStrap";
import Block from "../../Block";
import Button from "../../Button";
import Text from "../../Text";

const MediStrapProperties = MediStrap.getProperties();

function ImageInput({ label, imageSource, onChangeValue, error, buttonText = "Adicionar imagem" }) {
    const inputRef = useRef(null);
    const [previewImageSource, setPreviewImageSource] = useState(imageSource);

    return (
        <Block>
            <Block mb={12} height={30} >
                <Text height={24}>{label}</Text>
            </Block>
            {previewImageSource && <Block mb={12} height={300} >
                <img src={previewImageSource} style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />
            </Block>}
            <input
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                    onChangeValue(e.target.files[0]);
                    setPreviewImageSource(URL.createObjectURL(e.target.files[0]))
                }}
            />
            {!!previewImageSource ?
                <Block row>
                    <Button type="button" label="Apagar" color="red" size="small" onClick={() => {
                        onChangeValue(undefined);
                        setPreviewImageSource(undefined)
                        inputRef.current.value = "";
                    }} />
                </Block>
                :
                <Button type="button" label={buttonText} color="brand" size="small" onClick={() => inputRef.current.click()} />
            }
            {!!error && <Text
                size={11}
                height={16}
                color={MediStrapProperties.colors.alert100}
            >
                {error}
            </Text>}
        </Block>
    );
}

export default React.memo(ImageInput);