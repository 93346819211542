import React from "react";
import styled, { css } from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from '../../Block';
import Icon from "../../Icon";
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function CheckInput(
    {
        label,
        rightLabel,
        onChangeValue,
        value = false
    }
) {
    return (
        <Block row center pointer width="100%" onClick={() => !!onChangeValue && onChangeValue(!value)} space="between">
            <Block row center>
                <Square selected={value} center middle>
                    {!!value && <Icon name="Check" color={MediStrapProperties.colors.white100} />}
                </Square>
                {!!label && <Text noselect size={14} height={24} ml={8} color={value ? MediStrapProperties.colors.grey100 : MediStrapProperties.colors.grey50}>{label}</Text>}
            </Block>
            {rightLabel && <Block>
                {!!label && <Text noselect size={14} height={24} color={value ? MediStrapProperties.colors.grey100 : MediStrapProperties.colors.grey50}>{rightLabel}</Text>}
            </Block>}
        </Block>
    );
}

export default React.memo(CheckInput);

const Square = styled(Block)`
    width: 16px;
    height: 16px;
    border-radius: 2px;

    ${({ selected }) => css`
        background-color: ${selected ? MediStrapProperties.colors.brand100 : "transparent"};
        border: 1px solid ${selected ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.grey30};
    `}
`;