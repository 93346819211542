import { useMutation } from "@apollo/react-hooks";
import { useRef } from "react";
import * as yup from 'yup';
import { Components } from "../../../MediStrap";
import { MUTATION_FAQ_CREATE } from "../graphql/mutations";

function ModalCreate({ onRequestClose, onCreate, faqSubjectsOptions, isOpen }) {
    const createFormRef = useRef(null);

    const [mutationDataCreate, { loading: loadingDataCreate }] = useMutation(
        MUTATION_FAQ_CREATE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    onCreate();
                    onRequestClose();
                }
            }
        }
    );

    const createData = (params = {}) => {
        const { question, answer, faqSubjects } = params;
        
        mutationDataCreate({
            variables: {
                question,
                answer,
                subjects: faqSubjects
            }
        });
    }

    return (
        <Components.Modal
            title="Criar"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingDataCreate} size="medium" label="Criar" color="brand" onClick={() => createFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            <Components.Form
                innerRef={createFormRef}
                schema={yup.object().shape({
                    question: yup.string().required(),
                    answer: yup.string().required(),
                    faqSubjects: yup.array().required()
                })}
                onSubmit={(values) => {
                    createData({
                        faqSubjects: values.faqSubjects.map((item) => item.value),
                        question: values.question,
                        answer: values.answer
                    });
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.FormGroup title="Geral">
                                <Components.Block width={420}>
                                    <Components.Input error={errors.question} Component={Components.TextInput} name="question" label="Pergunta" placeholder="Pergunta" />
                                </Components.Block>
                                <Components.Block width={420}>
                                    <Components.Input error={errors.answer} Component={Components.TextAreaInput} name="answer" label="Resposta" placeholder="Resposta" />
                                </Components.Block>
                                <Components.Block width={420}>
                                    <Components.Input error={errors.faqSubjects} Component={Components.DropdownMultiInput} options={faqSubjectsOptions} name="faqSubjects" label="Tópicos" placeholder="Tópicos" description="Tópicos em que a pergunta se enquadra" />
                                </Components.Block>
                            </Components.FormGroup>
                        </form>
                    )
                }}
            </Components.Form>
        </Components.Modal>
    );
}

export default ModalCreate;
