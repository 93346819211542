import React from "react";
import Block from "../../Block";
import FormGroup from "../../FormGroup";
import Input from "../../Input";
import TextInput from "../../Inputs/TextInput";

function SocialMediaInput({ errors }) {
    return (
        <FormGroup>
            <Block width={420}>
                <Input error={errors.facebook} Component={TextInput} name="socialNetworks.facebook" label="Facebook" placeholder="Facebook do mentor" />
            </Block>
            <Block width={420}>
                <Input error={errors.instagram} Component={TextInput} name="socialNetworks.instagram" label="Instagram" placeholder="Instagram do mentor" />
            </Block>
            <Block width={420}>
                <Input error={errors.twitter} Component={TextInput} name="socialNetworks.twitter" label="Twitter" placeholder="Twitter do mentor" />
            </Block>
        </FormGroup>
    );
}

export default SocialMediaInput;
