import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import MediStrap from "../../MediStrap";
import Block from '../Block';
import Text from '../Text';
import Button from '../Button';

ReactModal.setAppElement('body');
const MediStrapProperties = MediStrap.getProperties();

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        border: "none",
        borderRadius: MediStrapProperties.style.borderRadius
    },
    overlay: {
        backgroundColor: "rgb(0 0 0 / 50%)"
    }
};

export default function ActionPanel1({ title, description, isOpen, onRequestClose, confirmButtonProps, cancelButtonProps, children, ...props }) {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            {...props}
        >
            <Block width={452} pt={40} pr={24} pb={24} pl={24}>
                <Block center>
                    <Icon className="icon-Alert_32px"></Icon>
                </Block>
                <Block center mt={20}>
                    <Text size={24} height={32} color={MediStrapProperties.colors.grey100}>{title}</Text>
                </Block>
                <Block center mt={8}>
                    <Text width={356} size={14} height={24} color={MediStrapProperties.colors.grey50} center>{description}</Text>
                </Block>
                <Block row mt={69}>
                    {!!cancelButtonProps && <Button width={"100%"} color="brand" {...cancelButtonProps} />}
                    {!!confirmButtonProps && <Button width={"100%"} color="empty" {...confirmButtonProps} />}
                </Block>
            </Block>
        </ReactModal>
    );
}

const Icon = styled.span`
    ::before {
        font-size: 32px;
    }
`;