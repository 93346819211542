import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../../MediStrap";
import components from "../../../../MediStrap/components";
import { MUTATION_GENRE_DELETE, MUTATION_GENRE_UPDATE } from "../../graphql/mutations";
import { QUERY_GENRE } from "../../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, dataId }) {
    const updateFormRef = useRef(null);

    const [data, setData] = useState(null);

    const [query] = useLazyQuery(
        QUERY_GENRE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    setData(result.data);
                }
            }
        }
    );

    const request = (params = {}) => {
        const { id } = params;

        query({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!dataId) {
            request({ id: dataId });
        }
    }, [dataId]);

    const [mutationDataUpdate, { loading: loadingDataUpdate }] = useMutation(
        MUTATION_GENRE_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    onUpdate(result.data);
                    onRequestClose();
                }
            }
        }
    );

    const updateData = (params = {}) => {
        const { name } = params;

        mutationDataUpdate({
            variables: {
                id: data.id,
                name
            }
        });
    }

    const [mutationDataDelete, { loading: loadingDataDelete }] = useMutation(
        MUTATION_GENRE_DELETE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    onDelete();
                    onRequestClose();
                }
            }
        }
    );

    const deleteData = () => {
        mutationDataDelete({
            variables: {
                id: data.id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setData(null);
        }
    }, [isOpen])

    return (
        <Components.Modal
            title="Editar"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block data center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingDataUpdate} size="medium" label="Editar" color="brand" onClick={() => updateFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            {!!data ?
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            name: data.name
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required()
                        })}
                        onSubmit={(values) => {
                            updateData(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.FormGroup title="Geral">
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.name} Component={components.TextInput} name="name" label="Nome" placeholder="Nome do tópico" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar"
                                            description="Esta ação irá eliminar o registo permanentemente "
                                            onClickDelete={() => deleteData({ id: data.id })}
                                            deleteButtonLoading={loadingDataDelete}
                                        />
                                    </Components.FormGroup>
                                </form>
                            )
                        }}
                    </Components.Form>
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.Modal>
    );
}

export default ModalUpdate;
