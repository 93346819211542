import React from "react";
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";

const MediStrapProperties = MediStrap.getProperties();

export default function PortletLine(
    {
        left,
        right
    }
) {
    return (
        <Header center row height={72} pt={28} pr={24} pb={28} pl={24} space="between" color={MediStrapProperties.colors.white100}>
            <Block row center>
                {left}
            </Block>
            <Block row center>
                {right}
            </Block>
        </Header>
    );
}

const Header = styled(Block)`
    
`;