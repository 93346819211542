import React, { useRef, useState } from "react";
import ReactPlayer from 'react-player';
import MediStrap from "../../../MediStrap";
import Block from "../../Block";
import Button from "../../Button";
import Text from "../../Text";

const MediStrapProperties = MediStrap.getProperties();

function VideoInput({
    label,
    onChangeValue,
    error,
    videoSource,
    buttonText = "Adicionar vídeo",
}) {
    const inputRef = useRef(null);
    const [previewVideoSource, setPreviewVideoSource] = useState(videoSource);

    return (
        <Block>
            <Block mb={12} height={30}>
                <Text height={24}>{label}</Text>
            </Block>
            <input
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                    onChangeValue(e.target.files[0]);
                    setPreviewVideoSource(
                        URL.createObjectURL(e.target.files[0])
                    );
                }}
            />
            {!!previewVideoSource ? (
                <>
                    <Block mb={12} height={300}>
                        <ReactPlayer
                            url={previewVideoSource}
                            width="100%"
                            height="100%"
                            controls={true}
                            playing
                            light="/videos/player-placeholder.png"
                        />
                    </Block>
                    <Block row>
                        <Button
                            type="button"
                            label="Apagar"
                            color="red"
                            size="small"
                            onClick={() => {
                                onChangeValue(undefined);
                                setPreviewVideoSource(undefined);
                                inputRef.current.value = "";
                            }}
                        />
                    </Block>
                </>
            ) : (
                <Button
                    type="button"
                    label={buttonText}
                    color="brand"
                    size="small"
                    onClick={() => inputRef.current.click()}
                />
            )}
            {!!error && (
                <Text
                    size={11}
                    height={16}
                    color={MediStrapProperties.colors.alert100}
                >
                    {error}
                </Text>
            )}
        </Block>
    );
}

export default React.memo(VideoInput);
