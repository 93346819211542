import gql from "graphql-tag";

export const MUTATION_BAND_CREATE = gql`
    mutation bandCreate(
        $name: String!
        $email: String!
        $phone: String!
        $address: String!
        $city: String!
        $zipCode: String!
        $image: Upload!
        $video: Upload
        $videoUrl: String
        $phoneToVote: String!
        $contest: ID!
        $description: String!
        $members: [InputMember!]!
        $status: BAND_STATUS!
        $mentors: [ID!]!
        $showrooms: [ID!]!
        $metatags: InputMetatags!
        $facebookUrl: String
        $instagramUrl: String
        $youtubeUrl: String
        $twitterUrl: String
        $tiktokUrl: String
    ) {
        data: bandCreate(
            name: $name
            email: $email
            phone: $phone
            address: $address
            city: $city
            zipCode: $zipCode
            image: $image
            video: $video
            videoUrl: $videoUrl
            phoneToVote: $phoneToVote
            contest: $contest
            description: $description
            members: $members
            status: $status
            mentors: $mentors
            showrooms: $showrooms
            metatags: $metatags,
            facebookUrl: $facebookUrl,
            instagramUrl: $instagramUrl,
            youtubeUrl: $youtubeUrl,
            twitterUrl: $twitterUrl,
            tiktokUrl: $tiktokUrl
        ) {
            id
            name
            status
            metatags
        }
    }
`;

export const MUTATION_BAND_UPDATE = gql`
    mutation bandUpdate(
        $id: ID!
        $name: String
        $email: String
        $phone: String
        $address: String
        $city: String
        $zipCode: String
        $image: Upload
        $video: Upload
        $videoUrl: String
        $phoneToVote: String
        $description: String
        $members: [InputMember!]
        $status: BAND_STATUS
        $mentors: [ID!]
        $showrooms: [ID!]
        $metatags: InputMetatags!
        $facebookUrl: String
        $instagramUrl: String
        $youtubeUrl: String
        $twitterUrl: String
        $tiktokUrl: String
    ) {
        data: bandUpdate(
            id: $id
            name: $name
            email: $email
            phone: $phone
            address: $address
            city: $city
            zipCode: $zipCode
            image: $image
            video: $video
            videoUrl: $videoUrl
            phoneToVote: $phoneToVote
            description: $description
            members: $members
            status: $status
            mentors: $mentors
            showrooms: $showrooms
            metatags: $metatags
            facebookUrl: $facebookUrl,
            instagramUrl: $instagramUrl,
            youtubeUrl: $youtubeUrl,
            twitterUrl: $twitterUrl,
            tiktokUrl: $tiktokUrl
        ) {
            id
            name
            status
            metatags
        }
    }
`;

export const MUTATION_BAND_DELETE = gql`
    mutation bandDelete($id: ID!) {
        data: bandDelete(id: $id) {
            id
        }
    }
`;
