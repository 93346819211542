import gql from "graphql-tag";

export const MUTATION_CONTEST_CREATE = gql`
    mutation contestCreate(
        $name: String!
        $startDate: String!
        $endDate: String!
        $registrationDate: String!
        $regulation: JSON!
        $slider: [InputSliderMedia]!
        $active: Boolean!
        $mentors: [ID!]
        $prizes: [InputPrize!]
        $legal: JSON!
        $metatags: InputMetatags!
        $contactEmail: String!
    ) {
        data: contestCreate(
            name: $name
            startDate: $startDate
            endDate: $endDate
            registrationDate: $registrationDate
            regulation: $regulation
            slider: $slider
            active: $active
            mentors: $mentors
            prizes: $prizes
            legal: $legal
            metatags: $metatags
            contactEmail: $contactEmail
        ) {
            id
            name
            startDate
            endDate
            registrationDate
            regulation
            slider
            mentors {
                id
                name
            }
            prizes {
                place
            }
            active
            legal
            metatags
        }
    }
`;
export const MUTATION_CONTEST_UPDATE = gql`
    mutation contestUpdate(
        $id: ID!
        $name: String
        $startDate: String
        $endDate: String
        $registrationDate: String
        $regulation: JSON
        $slider: [InputSliderMediaUpdate]
        $active: Boolean
        $mentors: [ID!]
        $prizes: [InputPrize!]
        $legal: JSON
        $metatags: InputMetatags!
        $contactEmail: String!
    ) {
        data: contestUpdate(
            id: $id
            name: $name
            startDate: $startDate
            endDate: $endDate,
            registrationDate: $registrationDate
            regulation: $regulation
            slider: $slider
            active: $active
            mentors: $mentors
            prizes: $prizes
            legal: $legal
            metatags: $metatags
            contactEmail: $contactEmail
        ) {
            id
            name
            active
            startDate
            endDate
            regulation
            slider
            mentors {
                id
                name
            }
            prizes {
                place
            }
            active
            legal
        }
    }
`;
export const MUTATION_CONTEST_DELETE = gql`
    mutation contestDelete($id: ID!) {
        data: contestDelete(id: $id) {
            id
        }
    }
`;
