import React from "react";
import ActionPanel1 from "../ActionPanel1";

export default function DeleteActionPanel(
    {
        isOpen = false,
        onRequestClose,
        title,
        description,
        onClickDelete
    }
) {
    return (
        <ActionPanel1
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            title={title}
            description={description + ", pretende mesmo assim continuar?"}
            confirmButtonProps={{
                label: "Eliminar",
                icon: "Trash",

                onClick: () => { onClickDelete && onClickDelete(); onRequestClose && onRequestClose(); }
            }}
            cancelButtonProps={{
                label: "Cancelar",
                onClick: onRequestClose
            }}
        />
    );
}