import AccordionItem from './AccordionItem';
import ActionPanel1 from './ActionPanel1';
import AdvancedTable from './AdvancedTable';
import Badge from './Badge';
import Block from './Block';
import Button from './Button';
import DeleteActionPanel from './DeleteActionPanel';
import DeleteLine from './DeleteLine';
import FieldArray from './FieldArray';
import Form from './Form';
import FormGroup from './FormGroup';
import Header1 from './Header1';
import Icon from './Icon';
import Input from './Input';
import InputGroup from './InputGroup';
import AccordionContent from './Inputs/AccordionContent';
import AccordionMedia from './Inputs/AccordionMedia';
import AccordionMembers from './Inputs/AccordionMembers';
import CheckInput from './Inputs/CheckInput';
import DateInput from './Inputs/DateInput';
import DropdownColorInput from './Inputs/DropdownColorInput';
import DropdownInput from './Inputs/DropdownInput';
import DropdownMultiInput from './Inputs/DropdownMultiInput';
import HTMLInput from './Inputs/HTMLInput';
import ImageInput from './Inputs/ImageInput';
import PasswordInput from './Inputs/PasswordInput';
import QuantityInput from './Inputs/QuantityInput';
import RadioInput from './Inputs/RadioInput';
import SliderInput from './Inputs/SliderInput';
import SocialMediaInput from './Inputs/SocialMediaInput';
import TextAreaInput from './Inputs/TextAreaInput';
import TextInput from './Inputs/TextInput';
import UploadInput from './Inputs/UploadInput';
import ValueWithSelectInput from './Inputs/ValueWithSelectInput';
import VideoInput from './Inputs/VideoInput';
import Loading from './Loading';
import Modal from './Modal';
import ModalThatSlides from './ModalThatSlides';
import Navigation from './Navigation';
import Portlet from './Portlet';
import PortletLine from './PortletLine';
import PortletMenu from './PortletMenu';
import TabMenu from './TabMenu';
import TabMenuLinkless from './TabMenuLinkless';
import Text from './Text';
import AccordionHomeSliderMedia from './Inputs/AccordionHomeSliderMedia';

export default {
    TextInput,
    PasswordInput,
    DropdownInput,
    Input,
    Block,
    Text,
    DropdownMultiInput,
    ValueWithSelectInput,
    DateInput,
    UploadInput,
    QuantityInput,
    Navigation,
    TextAreaInput,
    SliderInput,
    Loading,
    Button,
    Form,
    Header1,
    AdvancedTable,
    ModalThatSlides,
    Modal,
    ActionPanel1,
    FormGroup,
    InputGroup,
    Portlet,
    Icon,
    PortletLine,
    AccordionItem,
    DeleteLine,
    FieldArray,
    PortletMenu,
    Badge,
    CheckInput,
    RadioInput,
    TabMenu,
    ImageInput,
    TabMenuLinkless,
    VideoInput,
    DropdownColorInput,
    DeleteActionPanel,
    AccordionContent,
    AccordionMedia,
    SocialMediaInput,
    AccordionMembers,
    HTMLInput,
    AccordionHomeSliderMedia
};
