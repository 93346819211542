import React from "react";
import { Link } from 'react-scroll';
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import Icon from "../Icon";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

export default function PortletMenu(
    {

    }
) {
    return (
        <Wrapper>
            <Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Menu</Text>
            <Item activeClass="active" containerId="modal-content" to="informations" spy={true} smooth={true} offset={-100}>
                <Icon name="Information" color={MediStrapProperties.colors.grey60} />
                <Text ml={8} size={14} height={24} color={MediStrapProperties.colors.grey60}>Informação</Text>
            </Item>
            <Item activeClass="active" to="contacts" containerId="modal-content" spy={true} smooth={true} offset={-100}>
                <Icon name="Contacts" color={MediStrapProperties.colors.grey60} />
                <Text ml={8} size={14} height={24} color={MediStrapProperties.colors.grey60}>Contactos</Text>
            </Item>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    position: fixed;
    top: 172px;
    left: 150px;
`;

const Item = styled(Link)`
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    height: 48px;
    width: 212px;
    margin-top: 12px;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 16px;
    border-radius: ${MediStrapProperties.style.borderRadius}px;

    &.active {
        background-color: ${MediStrapProperties.colors.brand10};
        border: 1px solid ${MediStrapProperties.colors.brand30};

        span {
            color: ${MediStrapProperties.colors.brand100} !important;
        }

        span::before {
            color: ${MediStrapProperties.colors.brand100} !important;
        }
    }
`;