import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch } from "react-router-dom";
import { Components, MediStrap } from '../../MediStrap';
import Bands from '../../pages/Bands';
import Contests from '../../pages/Contests';
import Faqs from '../../pages/Faqs';
import Mentors from '../../pages/Mentors';
import Showrooms from '../../pages/Showrooms';
import Users from '../../pages/Users';
import checkAuth from '../../utils/checkAuth';
import { store } from '../../utils/store';

const MediStrapProperties = MediStrap.getProperties();

function Dashboard() {
    const [cookies, setCookie, removeCookie] = useCookies(["backoffice_session"]);
    const { user } = useContext(store);

    return (
        <Components.Block flex width="100%" style={{ overflow: "hidden", minWidth: "1366px" }}>
            <Components.Header1
                menu={[
                    { to: "/dashboard/users", icon: "Contacts", label: "Utilizadores" },
                    { to: "/dashboard/faq", icon: "Contacts", label: "Faqs" },
                    { to: "/dashboard/mentors", icon: "Contacts", label: "Mentores" },
                    { to: "/dashboard/contests", icon: "Contacts", label: "Contests" },
                    { to: "/dashboard/showrooms", icon: "Contacts", label: "Salas de espetáculos" }
                ]}
                name={user.name}
                actions={<Components.Button type="button" label="Logout" size="big" onClick={() => {
                    removeCookie("backoffice_session", { path: "/" });
                    window.location.href = '/';
                    return false;
                }} />}
            />
            <Components.Block width="100%" height="calc(100% - 72px)" color={MediStrapProperties.colors.grey5} pt={32} pb={32} pl={32} pr={32} style={{ overflow: "hidden", position: "relative", minHeight: window.innerHeight - 72 }}>
                <Switch>
                    <Route exact path="/dashboard/users" component={Users} />
                    <Route exact path="/dashboard/faq" component={Faqs} />
                    <Route exact path="/dashboard/mentors" component={Mentors} />
                    <Route exact path="/dashboard/contests" component={Contests} />
                    <Route exact path="/dashboard/showrooms" component={Showrooms} />
                    <Route exact path="/dashboard/contests/:contestId/bands" component={Bands} />
                    <Route
                        exact
                        path="/dashboard"
                        render={() => <Redirect to="/dashboard/users" />}
                    />
                </Switch>
            </Components.Block>
        </Components.Block>
    );
}

export default checkAuth(Dashboard);