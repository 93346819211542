import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import MediStrap from "../../MediStrap";
import Block from '../Block';
import Text from '../Text';

ReactModal.setAppElement('body');
const MediStrapProperties = MediStrap.getProperties();

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        border: "none",
        borderRadius: MediStrapProperties.style.borderRadius
    },
    overlay: {
        backgroundColor: "rgb(0 0 0 / 50%)"
    }
};

export default function Modal({ title, isOpen, onRequestClose, leftActions, rightActions, children, noInnerPadding, width = 676, height = "auto", ...props }) {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            {...props}
        >
            <Block width={width}>
                <Block center row color={MediStrapProperties.colors.white100} width="100%" height={72} pl={24} pr={28} space="between">
                    <Text size={16} height={24} color={MediStrapProperties.colors.grey100}>{title}</Text>
                    <Block pointer onClick={onRequestClose}>
                        <IconDelete className="icon-Delete"></IconDelete>
                    </Block>
                </Block>
                <Block height={1} color={MediStrapProperties.colors.grey20} />
                <ModalBody height={height} color={MediStrapProperties.colors.grey5} pt={!!noInnerPadding ? 0 : 24} pr={!!noInnerPadding ? 0 : 24} pb={!!noInnerPadding ? 0 : 24} pl={!!noInnerPadding ? 0 : 24}>
                    {children}
                </ModalBody>
                <Block height={1} color={MediStrapProperties.colors.grey20} />
                <Block height={88} pl={24} pr={24} row center space="between">
                    <Block>
                        {leftActions}
                    </Block>
                    <Block>
                        {rightActions}
                    </Block>
                </Block>
            </Block>
        </ReactModal>
    );
}

const IconDelete = styled.span`
    ::before {
        color: ${MediStrapProperties.colors.grey60};
    }
`;

const ModalBody = styled(Block)`
    overflow: scroll;
`;