import { useMutation } from "@apollo/react-hooks";
import { useRef } from "react";
import * as yup from "yup";
import { Components } from "../../../MediStrap";
import { MUTATION_MENTOR_CREATE } from "../graphql/mutations";
import {
    CHECK_IF_FILE_IS_IMAGE,
    CHECK_IF_FILE_IS_IMAGE_REQUIRED,
    CHECK_IF_FILE_IS_VIDEO_REQUIRED,
} from "../../../utils/commonData";

function ModalCreate({ onRequestClose, onCreate, genresOptions, isOpen }) {
    const createFormRef = useRef(null);

    const [mutationDataCreate, { loading: loadingDataCreate }] = useMutation(
        MUTATION_MENTOR_CREATE,
        {
            onError: () => { },
            onCompleted: (result) => {
                if (result && result.data) {
                    onCreate();
                    onRequestClose();
                }
            },
        }
    );

    const createData = (params = {}) => {
        const {
            name,
            imageThumbnail,
            genres,
            socialNetworks,
            media,
            content,
            descripion,
            metatags
        } = params;

        mutationDataCreate({
            variables: {
                name,
                imageThumbnail,
                genres,
                socialNetworks,
                media,
                content,
                descripion,
                metatags
            },
        });
    };

    return (
        <Components.ModalThatSlides
            id="criar"
            title="Criar"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button
                            type="submit"
                            loading={loadingDataCreate}
                            label="Criar"
                            color="brand"
                            onClick={() => createFormRef.current.handleSubmit()}
                        />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Geral", anchor: "general" },
                { icon: "Cicle", title: "Imagens", anchor: "images" },
                {
                    icon: "Contacts",
                    title: "Social media",
                    anchor: "socialMedia",
                },
                { icon: "Anexo", title: "Media", anchor: "media" },
                { icon: "Notes", title: "Conteudo", anchor: "content" },
                {
                    icon: "Paper",
                    title: "Metatags",
                    anchor: "metatags",
                },
            ]}
        >
            <Components.Form
                innerRef={createFormRef}
                schema={yup.object().shape({
                    name: yup.string().required(),
                    imageThumbnail: yup
                        .mixed()
                        .test(
                            "file-type",
                            "Utilize ficheiros do tipo jpg ou png",
                            CHECK_IF_FILE_IS_IMAGE_REQUIRED
                        )
                        .required(),
                    genres: yup.array().required(),
                    socialNetworks: yup.object(),
                    media: yup.array(
                        yup.object().shape({
                            mediaType: yup.string().required(),
                            image: yup
                                .mixed()
                                .test(
                                    "file-type",
                                    "Utilize ficheiros do tipo jpg ou png",
                                    CHECK_IF_FILE_IS_IMAGE_REQUIRED
                                )
                                .required(),
                            video: yup
                                .mixed()
                                .when("mediaType", (mediaType) => {
                                    if (mediaType === "1") {
                                        return yup
                                            .mixed()
                                            .test(
                                                "file-type",
                                                "Utilize ficheiros de video apropriados",
                                                CHECK_IF_FILE_IS_VIDEO_REQUIRED
                                            )
                                            .required();
                                    }
                                }),
                        })
                    ),
                    content: yup.array(
                        yup.object().shape({
                            title: yup.string().required(),
                            text: yup.string().required(),
                        })
                    ),
                    description: yup.string(),
                    metatagsDescription: yup.string(),
                    metatagsTitle: yup.string(),
                    metatagsImage: yup
                            .mixed()
                            .test(
                                "file-type",
                                "Utilize ficheiros do tipo jpg ou png",
                                CHECK_IF_FILE_IS_IMAGE
                            ),
                })}
                onSubmit={(values) => {
                    createData({
                        name: values.name,
                        imageThumbnail: values.imageThumbnail,
                        genres: values.genres.map((item) => item.value),
                        socialNetworks: values.socialNetworks || {},
                        media: values.media
                            ? values.media.map((item) => {
                                return {
                                    mediaType: item.mediaType,
                                    image: item.image,
                                    video:
                                        item.mediaType === "1"
                                            ? item.video
                                            : undefined,
                                };
                            })
                            : [],
                        content: values.content || [],
                        description: values.description,
                        metatags: {
                            image: values.metatagsImage,
                            description: values.metatagsDescription,
                            title: values.metatagsTitle
                        },
                    });
                }}
            >
                {({ values, errors, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.Portlet
                                icon="Information"
                                title="Geral"
                                anchor="general"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.name}
                                            Component={Components.TextInput}
                                            name="name"
                                            label="Nome"
                                            placeholder="Nome do mentor"
                                        />
                                    </Components.Block>
                                    <Components.Block width={600}>
                                        <Components.Input
                                            error={errors.description}
                                            Component={Components.TextInput}
                                            name="description"
                                            label="Descrição"
                                            placeholder="Descrição"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.genres}
                                            Component={
                                                Components.DropdownMultiInput
                                            }
                                            options={genresOptions}
                                            name="genres"
                                            label="Géneros"
                                            placeholder="Géneros"
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet
                                icon="Information"
                                title="Imagens"
                                anchor="images"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            Component={Components.ImageInput}
                                            name="imageThumbnail"
                                            label="Imagem thumbnail"
                                            error={errors.imageThumbnail}
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet
                                icon="Information"
                                title="Social media"
                                anchor="socialMedia"
                            >
                                <Components.SocialMediaInput errors={errors} />
                            </Components.Portlet>
                            <Components.Input
                                Component={Components.AccordionMedia}
                                name="media"
                                anchor="media"
                                errors={errors}
                            />
                            <Components.Input
                                Component={Components.AccordionContent}
                                name="content"
                                anchor="media"
                                errors={errors}
                            />
                            <Components.Portlet
                                icon="Paper"
                                title="Metatags"
                                anchor="metatags"
                            >
                                <Components.FormGroup>
                                    <Components.Input
                                        error={errors.metatagsTitle}
                                        Component={Components.TextInput}
                                        name="metatagsTitle"
                                        label="Título"
                                        placeholder="Título para colocar em metatags"
                                    />
                                    <Components.Input
                                        Component={Components.ImageInput}
                                        name="metatagsImage"
                                        label="Imagem"
                                        error={errors.metatagsImage}
                                    />
                                    <Components.Input
                                        error={errors.metatagsDescription}
                                        Component={Components.TextAreaInput}
                                        name="metatagsDescription"
                                        label="Descrição"
                                        placeholder="Descrição para colocar em metatags"
                                    />
                                </Components.FormGroup>
                            </Components.Portlet>
                        </form>
                    );
                }}
            </Components.Form>
        </Components.ModalThatSlides>
    );
}

export default ModalCreate;
