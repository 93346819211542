import gql from "graphql-tag";

export const MUTATION_GENRE_CREATE = gql`
    mutation genreCreate($name: String!) {
        data: genreCreate(name: $name) {
            id
            name
        }
    }
`;
export const MUTATION_GENRE_UPDATE = gql`
    mutation genreUpdate($id: ID!, $name: String) {
        data: genreUpdate(id: $id, name: $name) {
            id
            name
        }
    }
`;
export const MUTATION_GENRE_DELETE = gql`
    mutation genreDelete($id: ID!) {
        data: genreDelete(id: $id) {
            id
            name
        }
    }
`;
export const MUTATION_MENTOR_CREATE = gql`
    mutation mentorCreate(
        $name: String!
        $imageThumbnail: Upload!
        $genres: [ID!]!
        $socialNetworks: JSON!
        $media: [InputMedia]!
        $content: JSON!
        $description: String
        $metatags: InputMetatags!
    ) {
        data: mentorCreate(
            name: $name
            imageThumbnail: $imageThumbnail
            genres: $genres
            socialNetworks: $socialNetworks
            media: $media
            content: $content
            description: $description
            metatags: $metatags
        ) {
            id
            name
            imageThumbnail
            genres {
                id
                name
            }
            socialNetworks
            media
            content
            description
            metatags
        }
    }
`;
export const MUTATION_MENTOR_UPDATE = gql`
    mutation mentorUpdate(
        $id: ID!
        $imageThumbnail: Upload
        $name: String
        $genres: [ID!]
        $socialNetworks: JSON
        $media: [InputMediaUpdate]
        $content: JSON
        $description: String
        $metatags: InputMetatags!
    ) {
        data: mentorUpdate(
            id: $id
            imageThumbnail: $imageThumbnail
            name: $name
            genres: $genres
            socialNetworks: $socialNetworks
            media: $media
            content: $content
            description: $description
            metatags: $metatags
        ) {
            id
            name
            imageThumbnail
            genres {
                id
                name
            }
            socialNetworks
            media
            content
            description
            metatags
        }
    }
`;
export const MUTATION_MENTOR_DELETE = gql`
    mutation mentorDelete($id: ID!) {
        data: mentorDelete(id: $id) {
            id
        }
    }
`;
