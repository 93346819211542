import React from "react";
import AccordionItem from "../../AccordionItem";
import Block from "../../Block";
import Button from "../../Button";
import DeleteLine from "../../DeleteLine";
import FieldArray from "../../FieldArray";
import FormGroup from "../../FormGroup";
import Input from "../../Input";
import TextAreaInput from "../../Inputs/TextAreaInput";
import TextInput from "../../Inputs/TextInput";
import Portlet from "../../Portlet";


function AccordionContent({ name, title = "Content", value = [], anchor, errors }) {
    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                return (
                    <Portlet
                        anchor={anchor}
                        noInnerPadding
                        icon="Paper"
                        title={title}
                        actions={
                            <Button
                                type="button"
                                label={"Adicionar " + title.toLowerCase()}
                                color="brand"
                                size="small"
                                onClick={() =>
                                    arrayHelpers.insert(value.length + 1, {})
                                }
                            />
                        }
                    >
                        {value.map((item, index) => {
                            return (
                                <AccordionItem
                                    key={index}
                                    index={index}
                                    name={name}
                                    title={title + " #" + (index + 1)}
                                >
                                    <FormGroup title={title}>
                                        <Block>
                                            <Input
                                                Component={TextInput}
                                                name={`${name}[${index}].title`}
                                                error={
                                                    errors[
                                                    `${name}[${index}].title`
                                                    ]
                                                }
                                                label="Título"
                                            />
                                        </Block>
                                        <Block>
                                            <Input
                                                Component={TextAreaInput}
                                                name={`${name}[${index}].text`}
                                                error={
                                                    errors[
                                                    `${name}[${index}].text`
                                                    ]
                                                }
                                                label="Content"
                                            />
                                        </Block>
                                    </FormGroup>
                                    <FormGroup>
                                        <DeleteLine
                                            title={"Eliminar " + title.toLowerCase()}
                                            description={`Esta ação irá eliminar os dados relativos ao ${title.toLowerCase()} permanentemente`}
                                            onClickDelete={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        />
                                    </FormGroup>
                                </AccordionItem>
                            );
                        })}
                    </Portlet>
                );
            }}
        />
    );
}

export default AccordionContent;
