import { useMutation } from "@apollo/react-hooks";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../MediStrap";
import { CHECK_IF_FILE_IS_IMAGE, CHECK_IF_FILE_IS_IMAGE_REQUIRED, CHECK_IF_FILE_IS_VIDEO, CONSTANT_BANDS_STATUS } from "../../../utils/commonData";
import { MUTATION_BAND_CREATE } from "../graphql/mutations";

const MediStrapProperties = MediStrap.getProperties();

function ModalCreate({ onRequestClose, onCreate, isOpen, mentors = [], showrooms = [] }) {
    const { contestId } = useParams();

    const statusOptions = Object.keys(CONSTANT_BANDS_STATUS).map((key) => ({ label: CONSTANT_BANDS_STATUS[key], value: key }));

    const createFormRef = useRef(null);
    const [status, setStatus] = useState(statusOptions[0]);


    const [mutationDataCreate, { loading: loadingDataCreate }] = useMutation(
        MUTATION_BAND_CREATE,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    onCreate();
                    onRequestClose();
                }
            }
        }
    );

    const createData = (params = {}) => {
        const { name, email, phone, address, city, zipCode, image, video, videoUrl, phoneToVote, contest, description, members, mentors, showrooms, metatags, facebookUrl, instagramUrl, youtubeUrl, twitterUrl, tiktokUrl } = params;

        mutationDataCreate({
            variables: {
                name,
                email,
                phone,
                address,
                city,
                zipCode,
                image,
                video,
                videoUrl,
                phoneToVote,
                contest,
                description,
                members,
                status: status.value,
                mentors,
                showrooms,
                metatags,
                facebookUrl,
                instagramUrl,
                youtubeUrl,
                twitterUrl,
                tiktokUrl
            }
        });
    }

    return (
        <Components.ModalThatSlides
            id="criar"
            title="Criar"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingDataCreate} label="Criar" color="brand" onClick={() => createFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
            menuActions={
                <Components.Block mb={24}>
                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>Estado</Components.Text>
                    <Components.Block mt={8}>
                        <Components.DropdownInput monocolor options={statusOptions} value={status} onChangeValue={(value) => setStatus(value)} />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Geral", anchor: "general" },
                { icon: "Information", title: "Social Media", anchor: "socialmedia" },
                { icon: "Anexo", title: "Media", anchor: "media" },
                { icon: "Information", title: "Descrição", anchor: "description" },
                { icon: "Information", title: "Membros", anchor: "members" },
                { icon: "Paper", title: "Metatags", anchor: "metatags" },
            ]}
        >
            <Components.Form
                innerRef={createFormRef}
                schema={yup.object().shape({
                    name: yup.string().required(),
                    email: yup.string().required(),
                    phone: yup.string().required(),
                    address: yup.string().required(),
                    city: yup.string().required(),
                    zipCode: yup.string().required(),
                    image: yup.mixed().test('file-type', 'Utilize ficheiros do tipo jpg ou png', CHECK_IF_FILE_IS_IMAGE_REQUIRED).required(),
                    video: yup.mixed().test('file-type', 'Utilize ficheiros de video apropriados', CHECK_IF_FILE_IS_VIDEO).nullable(),
                    videoUrl: yup.string().url().nullable(),
                    phoneToVote: yup.string().required(),
                    description: yup.string().nullable(),
                    members: yup.array(yup.object().shape({
                        name: yup.string().required(),
                        role: yup.string().required()
                    })),
                    mentors: yup.array().required(),
                    showrooms: yup.array().required(),
                    metatagsDescription: yup.string(),
                    metatagsTitle: yup.string(),
                    metatagsImage: yup
                        .mixed()
                        .test(
                            "file-type",
                            "Utilize ficheiros do tipo jpg ou png",
                            CHECK_IF_FILE_IS_IMAGE
                        ),
                    facebookUrl: yup.string().url().nullable(),
                    instagramUrl: yup.string().url().nullable(),
                    youtubeUrl: yup.string().url().nullable(),
                    twitterUrl: yup.string().url().nullable(),
                    tiktokUrl: yup.string().url().nullable(),
                })}
                onSubmit={(values) => {
                    createData({
                        name: values.name,
                        email: values.email,
                        phone: values.phone,
                        address: values.address,
                        city: values.city,
                        zipCode: values.zipCode,
                        image: values.image,
                        video: values.video,
                        videoUrl: values.videoUrl,
                        city: values.city,
                        phoneToVote: values.phoneToVote,
                        description: values.description,
                        members: values.members || [],
                        mentors: values.mentors.map((mentor) => mentor.value),
                        showrooms: values.showrooms.map((showroom) => showroom.value),
                        contest: contestId,
                        metatags: {
                            image: values.metatagsImage,
                            description: values.metatagsDescription,
                            title: values.metatagsTitle,
                        },
                        facebookUrl: values.facebookUrl,
                        instagramUrl: values.instagramUrl,
                        youtubeUrl: values.youtubeUrl,
                        twitterUrl: values.twitterUrl,
                        tiktokUrl: values.tiktokUrl,
                    });
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.Portlet icon="Information" title="Geral" anchor="general">
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Nome" placeholder="Nome" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.email} Component={Components.TextInput} name="email" label="Email" placeholder="Email" />
                                    </Components.Block>
                                    <Components.Block width={220}>
                                        <Components.Input error={errors.phone} Component={Components.TextInput} name="phone" label="Telemóvel" placeholder="Telemóvel" />
                                    </Components.Block>
                                    <Components.Block>
                                        <Components.Input error={errors.address} Component={Components.TextInput} name="address" label="Morada" placeholder="Morada" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.city} Component={Components.TextInput} name="city" label="Cidade" placeholder="Cidade" />
                                    </Components.Block>
                                    <Components.Block width={220}>
                                        <Components.Input error={errors.zipCode} Component={Components.TextInput} name="zipCode" label="Código Postal" placeholder="Código Postal" />
                                    </Components.Block>
                                    <Components.Block width={220}>
                                        <Components.Input error={errors.phoneToVote} Component={Components.TextInput} name="phoneToVote" label="Phone to vote" placeholder="Phone to vote" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.mentors} Component={Components.DropdownMultiInput} options={mentors.map((mentor) => ({ label: mentor.name, value: mentor.id }))} name="mentors" label="Mentores" placeholder="Mentores" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.showrooms} Component={Components.DropdownMultiInput} options={showrooms.map((showroom) => ({ label: showroom.name, value: showroom.id }))} name="showrooms" label="Salas de espetáculo" placeholder="Salas de espetáculo" />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet icon="Information" title="Social Media" anchor="socialmedia">
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.facebookUrl} Component={Components.TextInput} name="facebookUrl" label="Facebook" placeholder="https://facebook.com" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.instagramUrl} Component={Components.TextInput} name="instagramUrl" label="Instragram" placeholder="https://instagram.com" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.youtubeUrl} Component={Components.TextInput} name="youtubeUrl" label="Youtube" placeholder="https://youtube.com" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.twitterUrl} Component={Components.TextInput} name="twitterUrl" label="Twitter" placeholder="https://twitter.com" />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.tiktokUrl} Component={Components.TextInput} name="tiktokUrl" label="Tiktok" placeholder="https://tiktok.com" />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet icon="Anexo" title="Media" anchor="media">
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input Component={Components.ImageInput} name="image" label="Imagem" error={errors.image} />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input Component={Components.VideoInput} name="video" label="Video" error={errors.video} />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input error={errors.videoUrl} Component={Components.TextInput} name="videoUrl" label="Video Url" />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet icon="Information" title="Descrição" anchor="description">
                                <Components.Input error={errors.description} Component={Components.TextAreaInput} name="description" placeholder="Descrtição da banda" />
                            </Components.Portlet>
                            <Components.Input Component={Components.AccordionMembers} name="members" anchor="members" errors={errors} />
                            <Components.Portlet icon="Paper" title="Metatags" anchor="metatags">
                                <Components.FormGroup>
                                    <Components.Input error={errors.metatagsTitle} Component={Components.TextInput} name="metatagsTitle" label="Título" placeholder="Título para colocar em metatags" />
                                    <Components.Input Component={Components.ImageInput} name="metatagsImage" label="Imagem" error={errors.metatagsImage} />
                                    <Components.Input error={errors.metatagsDescription} Component={Components.TextAreaInput} name="metatagsDescription" label="Descrição" placeholder="Descrição para colocar em metatags" />
                                </Components.FormGroup>
                            </Components.Portlet>
                        </form>
                    )
                }}
            </Components.Form>
        </Components.ModalThatSlides>
    );
}

export default ModalCreate;