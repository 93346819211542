import React, { useRef } from "react";
import styled, { css } from 'styled-components';
import MediStrap from "../../../MediStrap";
import Block from '../../Block';
import Loading from "../../Loading";
import Text from '../../Text';

const MediStrapProperties = MediStrap.getProperties();

function UploadInput(
    {
        label,
        description,
        error,
        success,
        loading,
        onChangeValue,
        value = undefined,
        disabled
    }
) {
    const inputRef = useRef(null);
    return (
        <Block width="100%">
            {!!label && <Text size={12} height={16} mb={8} color={MediStrapProperties.colors.grey100}>{label}</Text>}
            <Block style={{ position: "relative" }}>
                {(!error && !success && !loading) && <IconWrapper><span className="icon-Upload" /></IconWrapper>}
                {(!!error) && <StatusWrapper><span className="icon-Error" /></StatusWrapper>}
                {(!!success) && <StatusWrapper><span className="icon-Upload_Valid" /></StatusWrapper>}
                {(!!loading) && <StatusWrapper><Loading color={MediStrapProperties.colors.brand100} /></StatusWrapper>}
                <Input
                    error={error}
                    success={success}
                    value={value}
                    disabled={disabled}
                    onClick={() => (!disabled && !loading) && inputRef.current.click()}
                >
                    {!value && <Text size={14} height={24} color={MediStrapProperties.colors.grey50}>Attach your files here</Text>}
                    {!!value && <Text size={14} height={24} color={MediStrapProperties.colors.grey100}>{value.name}</Text>}
                </Input>
                <input
                    ref={inputRef}
                    style={{ display: "none" }}
                    type="file"
                    onChange={(e) => !!onChangeValue && onChangeValue(e.target.files[0])}
                />
            </Block>
            {
                (() => {
                    let color = null;
                    let text = null;
                    if (!!error) { text = typeof error === "string" ? error : null; color = MediStrapProperties.colors.alert100; }
                    else if (!!success) { text = typeof success === "string" ? success : null; color = MediStrapProperties.colors.validation100; }
                    else if (!!loading) { text = typeof loading === "string" ? loading : null; color = MediStrapProperties.colors.brand100; }
                    else if (!!description) { text = description; color = MediStrapProperties.colors.grey50; }

                    if (!!text) {
                        return (
                            <Block mt={8}>
                                <Text
                                    size={11}
                                    height={16}
                                    color={color}
                                >
                                    {error || success || loading || description}
                                </Text>
                            </Block>
                        );
                    }
                })()
            }
        </Block >
    );
}

export default React.memo(UploadInput);

const Input = styled.div`
    background-color: ${MediStrapProperties.colors.white100};
    height: 48px;
    color: ${MediStrapProperties.colors.grey100};
    border: 0;
    padding: 0 48px 0 16px;
    display: flex;
    align-items: center;
    border: 1px solid ${MediStrapProperties.colors.grey20};
    border-radius: ${MediStrapProperties.style.borderRadius}px;
    cursor: pointer;

    :hover {
        border-color: ${({ error, success }) => {
        if (!!error) {
            return MediStrapProperties.colors.alert100;
        }

        if (success) {
            return MediStrapProperties.colors.validation100;
        }

        return MediStrapProperties.colors.grey60;
    }};
    }

    ${({ disabled }) => !!disabled && css`
        cursor: auto;
        color: ${MediStrapProperties.colors.grey20};
        background-color: ${MediStrapProperties.colors.grey5};
        border-color: ${MediStrapProperties.colors.grey20};
    `}

    ${({ error }) => !!error && css`
        border-color:  ${MediStrapProperties.colors.alert100};
    `}

    ${({ success }) => !!success && css`
        border-color:  ${MediStrapProperties.colors.validation100};
    `}
`;

const IconWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

const StatusWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;