import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dashboard, Login } from "./layouts";
import DesignSystem from "./pages/DesignSystem";
import GlobalStyle from './styles/global';
import './styles/icons.css';
import './utils/yupMessages';

function App() {
  return (
    <>
      <ToastContainer theme="colored" />
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/designsystem" component={DesignSystem} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Redirect exact from="/" to="dashboard" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
