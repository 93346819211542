import { useMutation } from "@apollo/react-hooks";
import { useRef } from "react";
import * as yup from "yup";
import { Components } from "../../../MediStrap";
import {
    CHECK_IF_FILE_IS_IMAGE,
    CHECK_IF_FILE_IS_IMAGE_REQUIRED,
    CHECK_IF_FILE_IS_VIDEO_REQUIRED,
} from "../../../utils/commonData";
import AccordionPrizes from "../AccordionPrizes";
import { MUTATION_CONTEST_CREATE } from "../graphql/mutations";

function ModalCreate({ onRequestClose, onCreate, mentorsOptions, isOpen }) {
    const createFormRef = useRef(null);

    const [mutationDataCreate, { loading: loadingDataCreate }] = useMutation(
        MUTATION_CONTEST_CREATE,
        {
            onError: () => { },
            onCompleted: (result) => {
                if (result && result.data) {
                    onCreate();
                    onRequestClose();
                }
            },
        }
    );

    const createData = (params = {}) => {
        const {
            name,
            active,
            startDate,
            endDate,
            registrationDate,
            regulation,
            mentors,
            prizes,
            slider,
            legal,
            metatags,
            contactEmail
        } = params;

        mutationDataCreate({
            variables: {
                name,
                active,
                startDate,
                endDate,
                registrationDate,
                regulation,
                mentors,
                prizes,
                slider,
                legal,
                metatags,
                contactEmail
            },
        });
    };

    return (
        <Components.ModalThatSlides
            id="criar"
            title="Criar"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button
                            type="submit"
                            loading={loadingDataCreate}
                            size="medium"
                            label="Criar"
                            color="brand"
                            onClick={() => createFormRef.current.handleSubmit()}
                        />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Geral", anchor: "general" },
                {
                    icon: "Notes",
                    title: "ConteRegulamentoudo",
                    anchor: "regulation",
                },
                { icon: "Anexo", title: "Slider", anchor: "slider" },
                {
                    icon: "Contacts",
                    title: "Mentores",
                    anchor: "mentors",
                },
                {
                    icon: "Paper",
                    title: "Metatags",
                    anchor: "metatags",
                },
            ]}
        >
            <Components.Form
                innerRef={createFormRef}
                initialValues={{
                    active: false,
                }}
                schema={yup.object().shape({
                    name: yup.string().required(),
                    active: yup.boolean().required(),
                    startDate: yup.string().required(),
                    endDate: yup.string().required(),
                    registrationDate: yup.string().required(),
                    regulation: yup.array(
                        yup.object().shape({
                            title: yup.string().required(),
                            text: yup.string(),
                        })
                    ),
                    prizes: yup
                        .array(
                            yup.object().shape({
                                place: yup.string().required(),
                                items: yup
                                    .array(
                                        yup.object().shape({
                                            text: yup.string().required(),
                                            overline: yup.boolean().required(),
                                        })
                                    )
                                    .required(),
                            })
                        ),
                    slider: yup.array(
                        yup.object().shape({
                            mediaType: yup.string().required(),
                            image: yup
                                .mixed()
                                .test(
                                    "file-type",
                                    "Utilize ficheiros do tipo jpg ou png",
                                    CHECK_IF_FILE_IS_IMAGE_REQUIRED
                                )
                                .required(),
                            video: yup
                                .mixed()
                                .when("mediaType", (mediaType) => {
                                    if (mediaType === "1") {
                                        return yup
                                            .mixed()
                                            .test(
                                                "file-type",
                                                "Utilize ficheiros de video apropriados",
                                                CHECK_IF_FILE_IS_VIDEO_REQUIRED
                                            )
                                            .required();
                                    }
                                }),
                            smallTitle: yup.string(),
                            title: yup.string(),
                            subtitle: yup.string(),
                            description: yup.string(),
                        })
                    ),
                    mentors: yup.array(),
                    metatagsDescription: yup.string(),
                    metatagsTitle: yup.string(),
                    metatagsImage: yup
                        .mixed()
                        .test(
                            "file-type",
                            "Utilize ficheiros do tipo jpg ou png",
                            CHECK_IF_FILE_IS_IMAGE
                        ),
                    contactEmail: yup.string().required(),
                })}
                onSubmit={(values) => {
                    createData({
                        name: values.name,
                        active: values.active,
                        startDate: values.startDate,
                        endDate: values.endDate,
                        registrationDate: values.registrationDate,
                        regulation: values.regulation,
                        mentors: values.mentors.map((item) => item.value),
                        prizes: values.prizes || [],
                        slider: values.slider
                            ? values.slider.map((item) => {
                                return {
                                    mediaType: item.mediaType,
                                    image: item.image,
                                    video:
                                        item.mediaType === "1"
                                            ? item.video
                                            : undefined,

                                    smallTitle: item.smallTitle,
                                    title: item.title,
                                    subtitle: item.subtitle,
                                    description: item.description,
                                };
                            })
                            : [],
                        legal: values.legal,
                        metatags: {
                            image: values.metatagsImage,
                            description: values.metatagsDescription,
                            title: values.metatagsTitle
                        },
                        contactEmail: values.contactEmail
                    });
                }}
            >
                {({ values, errors, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.Portlet
                                icon="Information"
                                title="Período"
                                anchor="general"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.name}
                                            Component={Components.TextInput}
                                            name="name"
                                            label="Name"
                                            placeholder="name"
                                        />
                                    </Components.Block>
                                    <Components.Input
                                        error={errors.active}
                                        Component={Components.CheckInput}
                                        name="active"
                                        label="Activo"
                                    />
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.contactEmail}
                                            Component={Components.TextInput}
                                            name="contactEmail"
                                            label="Email de contacto"
                                            placeholder="exemplo@mail.com"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.startDate}
                                            Component={Components.DateInput}
                                            name="startDate"
                                            label="Data de início"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.endDate}
                                            Component={Components.DateInput}
                                            name="endDate"
                                            label="Data de fim"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.endDate}
                                            Component={Components.DateInput}
                                            name="registrationDate"
                                            label="Data limite para incrição"
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Input
                                Component={Components.AccordionContent}
                                title="Regulation"
                                name="regulation"
                                anchor="regulation"
                                errors={errors}
                            />
                            <Components.Input
                                Component={Components.AccordionContent}
                                title="Legal"
                                name="legal"
                                anchor="legal"
                                errors={errors}
                            />
                            <Components.Input
                                Component={Components.AccordionHomeSliderMedia}
                                name="slider"
                                anchor="slider"
                                errors={errors}
                            />
                            <Components.Input
                                Component={AccordionPrizes}
                                name="prizes"
                                anchor="prizes"
                                errors={errors}
                            />
                            <Components.Portlet
                                icon="Contacts"
                                title="Mentores"
                                anchor="mentors"
                            >
                                <Components.Block width={420}>
                                    <Components.Input
                                        error={errors.mentors}
                                        Component={
                                            Components.DropdownMultiInput
                                        }
                                        options={mentorsOptions}
                                        name="mentors"
                                        label="Mentores"
                                        placeholder="Mentores"
                                        description="Mentores para participar no concurso"
                                    />
                                </Components.Block>
                            </Components.Portlet>
                            <Components.Portlet
                                icon="Paper"
                                title="Metatags"
                                anchor="metatags"
                            >
                                <Components.FormGroup>
                                    <Components.Input
                                        error={errors.metatagsTitle}
                                        Component={Components.TextInput}
                                        name="metatagsTitle"
                                        label="Título"
                                        placeholder="Título para colocar em metatags"
                                    />
                                    <Components.Input
                                        Component={Components.ImageInput}
                                        name="metatagsImage"
                                        label="Imagem"
                                        error={errors.metatagsImage}
                                    />
                                    <Components.Input
                                        error={errors.metatagsDescription}
                                        Component={Components.TextAreaInput}
                                        name="metatagsDescription"
                                        label="Descrição"
                                        placeholder="Descrição para colocar em metatags"
                                    />
                                </Components.FormGroup>
                            </Components.Portlet>
                        </form>
                    );
                }}
            </Components.Form>
        </Components.ModalThatSlides>
    );
}

export default ModalCreate;
