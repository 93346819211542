export default {
    style: {
        borderRadius: 4
    },
    colors: {
        grey100: "#2A2A2C",
        grey90: "#3F3F41",
        grey80: "#555556",
        grey60: "#7F7F80",
        grey50: "#949495",
        grey30: "#BFBFC0",
        grey20: "#D4D4D5",
        grey10: "#EAEAEA",
        grey5: "#F4F4F4",

        alert10: "#FFE7E8",
        alert30: "#FFB7BA",
        alert100: "#FF0F18",
        alert120: "#CF0B12",

        validation100: "#00C57E",

        information100: "#FFC632",
        information10: "#FFF9EA",

        white100: "#FFFFFF",

        // brand140: "#35208A",
        // brand120: "#5E40D7",
        // brand100: "#7452FF",
        // brand50: "#B9A8FF",
        // brand30: "#D5CBFF",
        // brand10: "#F1EEFF",
        // brand5: "#F8F6FF"

        //brand140: "#184934",
        //brand120: "#236146",
        //brand100: "#368D67",
        //brand50: "#9AC6B3",
        //brand30: "#C3DDD1",
        //brand10: "#EBF4F0",
        //brand5: "#F5F9F7"

        brand140: "#236EFD",
        brand120: "#0598FF",
        brand100: "#72C5FF",
        brand50: "#82CCFF",
        brand30: "#CDEAFF",
        brand10: "#EFF8FF",
        brand5: "#F7FBFF"
    }
}