import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Components, MediStrap } from "../../MediStrap";
import { QUERY_FAQS, QUERY_FAQ_SUBJECTS } from './graphql/queries';
import { CONSTANT_PER_PAGE_OPTIONS } from '../../utils/commonData';
import ModalCreate from './ModalCreate';
import ModalUpdate from './ModalUpdate';
import ModalFaqSubjects from './ModalFaqSubjects';

const MediStrapProperties = MediStrap.getProperties();
const perPageOptions = CONSTANT_PER_PAGE_OPTIONS;

function Faqs() {
    const [isModalFaqSubjectsOpen, setModalFaqSubjectsOpen] = useState(false);
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [isModalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [rowId, setRowId] = useState(false);

    const [data, setData] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            // orderBy: "name_ASC"
        }
    });

    const [orderBy, setOrderBy] = useState(null);
    const [search, setSearch] = useState("");

    const [query] = useLazyQuery(
        QUERY_FAQS,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    setData(result.data);
                }
            }
        }
    );

    const request = (params = {}) => {
        const { perPage, currentPage } = params;

        query({
            variables: {
                perPage: perPage || data.pagination.perPage,
                currentPage: currentPage || data.pagination.currentPage,
                search: search !== "" ? search : undefined,
                orderBy: !!orderBy ? `${orderBy.column}_${orderBy.order}` : undefined
            }
        });
    }

    useEffect(() => {
        request({ perPage: data.pagination.perPage, currentPage: 1 });
    }, [search, orderBy]);

    const [dataFaqSubjects, setDataFaqSubjects] = useState({
        rows: [],
        pagination: {
            total: 0,
            perPage: 10,
            lastPage: 0,
            currentPage: 1,
            hasPreviousPage: false,
            hasNextPage: false,
            // orderBy: "name_ASC"
        }
    });

    const [queryFaqSubjects] = useLazyQuery(
        QUERY_FAQ_SUBJECTS,
        {
            onError: () => {

            },
            onCompleted: (result) => {
                if (result && result.data) {
                    setDataFaqSubjects(result.data);
                }
            }
        }
    );

    const requestFaqSubjects = (params = {}) => {
        const { perPage, currentPage } = params;

        queryFaqSubjects({
            variables: {
                perPage: perPage || dataFaqSubjects.pagination.perPage,
                currentPage: currentPage || dataFaqSubjects.pagination.currentPage
            }
        });
    }

    useEffect(() => {
        requestFaqSubjects({ perPage: dataFaqSubjects.pagination.perPage, currentPage: 1 });
    }, []);


    return (
        <>
            <Components.Block row space="between">
                <Components.Block row>
                    <Components.Block width={444}>
                        <Components.TextInput icon="Search" placeholder="Procurar" value={search} onChangeValue={(value) => setSearch(value)} />
                    </Components.Block>
                    <Components.Block row center width={163} ml={20}>
                        <Components.DropdownInput onChangeValue={(option) => request({ perPage: option.value, currentPage: 1 })} value={perPageOptions.find((item) => item.value == data.pagination.perPage)} options={perPageOptions} />
                    </Components.Block>
                    <Components.Block ml={12}>
                        <Components.Navigation
                            perPage={data.pagination.perPage}
                            currentPage={data.pagination.currentPage}
                            total={data.pagination.total}
                            hasPreviousPage={data.pagination.hasPreviousPage}
                            hasNextPage={data.pagination.hasNextPage}
                            onClickPrevious={() => request({ currentPage: (data.pagination.currentPage - 1) })}
                            onClickNext={() => request({ currentPage: (data.pagination.currentPage + 1) })}
                        />
                    </Components.Block>
                </Components.Block>
                <Components.Block row space="between" width={200}>
                    <Components.Button color="brand" label="Tópicos" onClick={() => setModalFaqSubjectsOpen(true)} />
                    <Components.Button color="brand" label="Criar" onClick={() => setModalCreateOpen(true)} />
                </Components.Block>
            </Components.Block>
            <Components.Block flex pt={32} style={{ overflow: "hidden" }}>
                <Components.AdvancedTable
                    columns={[
                        {
                            label: "Pergunta",
                            value: "question",
                            width: 500,
                            order: false
                        },
                        {
                            label: "Resposta",
                            value: "answer",
                            width: 1000,
                            order: false
                        },
                        {
                            label: "Tópicos",
                            value: "subjects",
                            order: false
                        }
                    ]}
                    rows={data.rows.map((row) => {
                        return ({
                            question: <Components.Block row center height={56}>
                                <Components.Block mr={12} pointer>
                                    <Components.Button type="button" icon="Edit" size="squareSmall" color="empty" onClick={() => { setRowId(row.id); setModalUpdateOpen(true); }} />
                                </Components.Block>
                                <Components.Block>
                                    <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={row.question}>{row.question}</Components.Text>
                                </Components.Block>
                            </Components.Block>,
                            answer: <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={row.answer}>{row.answer}</Components.Text>,
                            subjects: <Components.Block row>
                                {
                                    row.subjects.map((item, index) => (
                                        <>
                                            <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100} title={item.name}>{item.name}</Components.Text>
                                            {(index !== row.subjects.length - 1) && <Components.Text size={14} height={24} color={MediStrapProperties.colors.grey100}>,&nbsp;</Components.Text>}
                                        </>
                                    ))
                                }

                            </Components.Block>
                        })
                    })}
                    noBorderRadius
                />
            </Components.Block>

            <ModalFaqSubjects
                onRequestClose={() => setModalFaqSubjectsOpen(false)}
                onCreate={() => requestFaqSubjects({ currentPage: 1 })}
                onUpdate={(updateRow) => {
                    const index = dataFaqSubjects.rows.findIndex((item) => item.id == updateRow.id);
                    dataFaqSubjects.rows[index] = updateRow;
                    setDataFaqSubjects({ ...dataFaqSubjects });
                }}
                onDelete={() => {
                    requestFaqSubjects();
                }}
                isOpen={isModalFaqSubjectsOpen}
                faqSubjects={dataFaqSubjects}
            />

            <ModalCreate
                onRequestClose={() => setModalCreateOpen(false)}
                onCreate={() => request({ currentPage: 1 })}
                isOpen={isModalCreateOpen}
                faqSubjectsOptions={dataFaqSubjects.rows.map((item) => { return { label: item.name, value: item.id } })}
            />

            <ModalUpdate
                onRequestClose={() => {
                    setRowId(null);
                    setModalUpdateOpen(false);
                }}
                onUpdate={(updateRow) => {
                    const index = data.rows.findIndex((item) => item.id == updateRow.id);
                    data.rows[index] = updateRow;
                    setData({ ...data });
                }}
                onDelete={() => {
                    request();
                }}
                isOpen={isModalUpdateOpen}
                dataId={rowId}
                faqSubjectsOptions={dataFaqSubjects.rows.map((item) => { return { label: item.name, value: item.id } })}
            />
        </>
    );
}

export default Faqs;
