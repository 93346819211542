import React from "react";
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

export default function FormGroup(
    {
        title,
        description,
        children
    }
) {
    return (
        <Wrapper>
            {(!!title || !!description) && <Block mb={24}>
                {!!title && <Block>
                    <Text size={16} height={24} color={MediStrapProperties.colors.grey100}>{title}</Text>
                </Block>}
                {!!description && <Block mt={4}>
                    <Text size={12} height={16} color={MediStrapProperties.colors.grey50}>{description}</Text>
                </Block>}
            </Block>}
            <Block>
                {!!children && !Array.isArray(children) ? children : children.map((child, index) => {
                    return (<Block key={index} mb={children.length - 1 === index ? 0 : 24}>{child}</Block>);
                })}
            </Block>
        </Wrapper>
    );
}


const Wrapper = styled(Block)`
    border-bottom: 1px solid ${MediStrapProperties.colors.grey20};
    padding-top: 24px;
    padding-bottom: 24px;

    :first-of-type {
        padding-top: 0;
    }

    :last-of-type {
        border: none;
        padding-bottom: 0;
    }
`;