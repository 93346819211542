import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Components } from '../../MediStrap';
import MediStrap from "../../MediStrap/MediStrap";
import { SvgLogoBlack } from '../../svgs';
import { getClientToken, setClientToken } from '../../utils/client';
import { MUTATION_USER_LOGIN } from './mutations';

const MediStrapProperties = MediStrap.getProperties();

function Login() {
    const [cookies, setCookie] = useCookies(["backoffice_session"]);
    const history = useHistory();

    const [mutateLogin, { loading }] = useMutation(
        MUTATION_USER_LOGIN,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userLogin) {
                    setClientToken(data.userLogin.token);
                    setCookie("backoffice_session", data.userLogin.token, { path: '/' });
                }
            }
        }
    );

    if (getClientToken() || cookies.backoffice_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Components.Block flex center middle width="100%" height="100%" color={MediStrapProperties.colors.brand100} style={{ overflow: "hidden", minWidth: "1366px" }}>
            <Components.Block column color={MediStrapProperties.colors.white100} width={384} pt={60} pb={40} pl={40} pr={40} br={MediStrapProperties.style.borderRadius}>
                <Components.Block center>
                    <img src={process.env.PUBLIC_URL + '/imagem_logo.png'} />
                </Components.Block>
                <Components.Form
                    schema={yup.object().shape({
                        email: yup.string().email().required(),
                        password: yup.string().required()
                    })}
                    onSubmit={(values) => {
                        mutateLogin({ variables: values });
                    }}
                >
                    {({
                        errors,
                        handleSubmit,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Components.Block mt={56}>
                                    <Components.Input error={errors.email} Component={Components.TextInput} name="email" label="Email" placeholder="Email de acesso" />
                                </Components.Block>
                                <Components.Block mt={16}>
                                    <Components.Input error={errors.password} Component={Components.PasswordInput} name="password" label="Password" description="Password criada no backoffice pelo administrador" placeholder="Password associada" />
                                </Components.Block>
                                <Components.Block mt={60}>
                                    <Components.Button loading={loading} width="100%" type="submit" label="Sign in" icon="Lock" color="brand" />
                                </Components.Block>
                            </form>
                        )
                    }}
                </Components.Form>
            </Components.Block>
        </Components.Block>
    );
}

export default Login;