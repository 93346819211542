import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import MediStrap from "../../MediStrap";
import Block from "../Block";
import Icon from "../Icon";
import Text from "../Text";

const MediStrapProperties = MediStrap.getProperties();

export default function TabMenu(
    {
        tabs = []
    }
) {
    const location = useLocation();
    return (
        <Header height={56} pt={20} pb={20} color={MediStrapProperties.colors.white100} row center>
            {tabs.map((tab, index) => {
                return (
                    <TabItem flex key={index} height={56}>
                        <Link to={tab.to} activeClassName="selected">
                            <Block ml={24} height={56} flex center row>
                                {!!tab.icon && <Block mr={4}>
                                    <Icon
                                        name={tab.icon}
                                        color={location.pathname === tab.to ? MediStrapProperties.colors.brand100 : MediStrapProperties.colors.grey50}
                                    />
                                </Block>}
                                {!!tab.label && <Text height={24} size={14}>{tab.label}</Text>}
                            </Block>
                        </Link>
                    </TabItem>
                );
            })}
        </Header>
    );
}

const Header = styled(Block)`
    border-radius: 4px 4px 0px 0px;
`;

const Link = styled(NavLink)`
    text-decoration: none;
    height: 56px;
    position: flex;

    span {
        color: ${MediStrapProperties.colors.grey50} !important;
    }
    
    &.selected span {
        color: ${MediStrapProperties.colors.grey100} !important;
    }
`;

const TabItem = styled(Block)`
    border-right: 1px solid ${MediStrapProperties.colors.grey20};
    &:last-child {
        border-right: 0px !important;
    }
`;