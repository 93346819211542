import React from "react";
import styled from "styled-components";
import Block from "../Block";

export default function InputGroup(
    {
        children,
        ...props
    }
) {
    return (
        <Wrapper {...props}>
            {!!children && !Array.isArray(children) ? children : children.map((child, index) => {
                return (<Block mb={children.length - 1 === index ? 0 : 24}>{child}</Block>);
            })}
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    margin-bottom: 24px;

    :last-of-type {
        margin-bottom: 0;
    }
`;