import { useMutation } from "@apollo/react-hooks";
import { useRef } from "react";
import * as yup from "yup";
import { Components } from "../../../MediStrap";
import {
    CHECK_IF_FILE_IS_IMAGE,
    CHECK_IF_FILE_IS_IMAGE_REQUIRED,
    CHECK_IF_FILE_IS_VIDEO_REQUIRED,
} from "../../../utils/commonData";
import { MUTATION_SHOWROOM_CREATE } from "../graphql/mutations";

function ModalCreate({ onRequestClose, onCreate, isOpen }) {
    const createFormRef = useRef(null);

    const [mutationDataCreate, { loading: loadingDataCreate }] = useMutation(
        MUTATION_SHOWROOM_CREATE,
        {
            onError: () => {},
            onCompleted: (result) => {
                if (result && result.data) {
                    onCreate();
                    onRequestClose();
                }
            },
        }
    );

    const createData = (params = {}) => {
        const {
            name,
            capacity,
            rooms,
            foodHall,
            imageThumbnail,
            location,
            latitude,
            longitude,
            socialNetworks,
            media,
            content,
            metatags,
        } = params;

        mutationDataCreate({
            variables: {
                name,
                capacity,
                rooms,
                foodHall,
                imageThumbnail,
                location,
                latitude,
                longitude,
                socialNetworks,
                media,
                content,
                metatags,
            },
        });
    };

    return (
        <Components.ModalThatSlides
            id="criar"
            title="Criar"
            width="100%"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button
                            type="submit"
                            loading={loadingDataCreate}
                            label="Criar"
                            color="brand"
                            onClick={() => createFormRef.current.handleSubmit()}
                        />
                    </Components.Block>
                </Components.Block>
            }
            menu={[
                { icon: "Information", title: "Geral", anchor: "general" },
                { icon: "Cicle", title: "Especificações", anchor: "specs" },
                { icon: "Cicle", title: "Imagens", anchor: "images" },
                { icon: "Angle_down", title: "Location", anchor: "location" },
                { icon: "Contacts", title: "Social media", anchor: "social" },
                { icon: "Anexo", title: "Media", anchor: "media" },
                { icon: "Notes", title: "Conteudo", anchor: "content" },
                { icon: "Paper", title: "Metatags", anchor: "metatags" },
            ]}
        >
            <Components.Form
                innerRef={createFormRef}
                schema={yup.object().shape({
                    name: yup.string().required(),
                    imageThumbnail: yup
                        .mixed()
                        .test(
                            "file-type",
                            "Utilize ficheiros do tipo jpg ou png",
                            CHECK_IF_FILE_IS_IMAGE_REQUIRED
                        )
                        .required(),
                    capacity: yup.number().integer().required(),
                    rooms: yup.number().integer().required(),
                    foodHall: yup.string().required(),
                    location: yup.string().required(),
                    latitude: yup.string().required(),
                    longitude: yup.string().required(),
                    socialNetworks: yup.object(),
                    media: yup.array(
                        yup.object().shape({
                            mediaType: yup.string().required(),
                            image: yup
                                .mixed()
                                .test(
                                    "file-type",
                                    "Utilize ficheiros do tipo jpg ou png",
                                    CHECK_IF_FILE_IS_IMAGE_REQUIRED
                                )
                                .required(),
                            video: yup
                                .mixed()
                                .when("mediaType", (mediaType) => {
                                    if (mediaType === "1") {
                                        return yup
                                            .mixed()
                                            .test(
                                                "file-type",
                                                "Utilize ficheiros de video apropriados",
                                                CHECK_IF_FILE_IS_VIDEO_REQUIRED
                                            )
                                            .required();
                                    }
                                }),
                        })
                    ),
                    content: yup.array(
                        yup.object().shape({
                            title: yup.string().required(),
                            text: yup.string().required(),
                        })
                    ),
                    metatagsDescription: yup.string(),
                    metatagsTitle: yup.string(),
                    metatagsImage: yup
                        .mixed()
                        .test(
                            "file-type",
                            "Utilize ficheiros do tipo jpg ou png",
                            CHECK_IF_FILE_IS_IMAGE
                        ),
                })}
                onSubmit={(values) => {
                    createData({
                        name: values.name,
                        capacity: parseInt(values.capacity),
                        rooms: parseInt(values.rooms),
                        foodHall: values.foodHall,
                        imageThumbnail: values.imageThumbnail,
                        location: values.location,
                        latitude: values.latitude,
                        longitude: values.longitude,
                        socialNetworks: values.socialNetworks || {},
                        media: values.media || [],
                        content: values.content || [],
                        metatags: {
                            image: values.metatagsImage,
                            description: values.metatagsDescription,
                            title: values.metatagsTitle,
                        },
                    });
                }}
            >
                {({ values, errors, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Components.Portlet
                                icon="Information"
                                title="Geral"
                                anchor="general"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.name}
                                            Component={Components.TextInput}
                                            name="name"
                                            label="Nome"
                                            placeholder="Nome"
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet
                                icon="Cicle"
                                title="Especificações"
                                anchor="specs"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            Component={Components.QuantityInput}
                                            name="capacity"
                                            label="Capacidade"
                                            error={errors.capacity}
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            Component={Components.QuantityInput}
                                            name="rooms"
                                            label="Número de salas"
                                            error={errors.rooms}
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.foodHall}
                                            Component={Components.TextInput}
                                            name="foodHall"
                                            label="Área de alimentação"
                                            placeholder="Área de alimentação"
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet
                                icon="Cicle"
                                title="Imagens"
                                anchor="images"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            Component={Components.ImageInput}
                                            name="imageThumbnail"
                                            label="Imagem thumbnail"
                                            error={errors.imageThumbnail}
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet
                                icon="Angle_down"
                                title="Location"
                                anchor="location"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.location}
                                            Component={Components.TextInput}
                                            name="location"
                                            label="Location"
                                            placeholder="Location"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.latitude}
                                            Component={Components.TextInput}
                                            name="latitude"
                                            label="Latitude"
                                            placeholder="Latitude"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.longitude}
                                            Component={Components.TextInput}
                                            name="longitude"
                                            label="Longitude"
                                            placeholder="Longitude"
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Portlet
                                icon="Contacts"
                                title="Social"
                                anchor="social"
                            >
                                <Components.FormGroup>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.facebook}
                                            Component={Components.TextInput}
                                            name="socialNetworks.facebook"
                                            label="Facebook"
                                            placeholder="Facebook"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.instagram}
                                            Component={Components.TextInput}
                                            name="socialNetworks.instagram"
                                            label="Instagram"
                                            placeholder="Instagram"
                                        />
                                    </Components.Block>
                                    <Components.Block width={420}>
                                        <Components.Input
                                            error={errors.twitter}
                                            Component={Components.TextInput}
                                            name="socialNetworks.twitter"
                                            label="Twitter"
                                            placeholder="Twitter"
                                        />
                                    </Components.Block>
                                </Components.FormGroup>
                            </Components.Portlet>
                            <Components.Input
                                Component={Components.AccordionMedia}
                                name="media"
                                anchor="media"
                                errors={errors}
                            />
                            <Components.Input
                                Component={Components.AccordionContent}
                                name="content"
                                anchor="content"
                                errors={errors}
                            />
                            <Components.Portlet
                                icon="Paper"
                                title="Metatags"
                                anchor="metatags"
                            >
                                <Components.FormGroup>
                                    <Components.Input
                                        error={errors.metatagsTitle}
                                        Component={Components.TextInput}
                                        name="metatagsTitle"
                                        label="Título"
                                        placeholder="Título para colocar em metatags"
                                    />
                                    <Components.Input
                                        Component={Components.ImageInput}
                                        name="metatagsImage"
                                        label="Imagem"
                                        error={errors.metatagsImage}
                                    />
                                    <Components.Input
                                        error={errors.metatagsDescription}
                                        Component={Components.TextAreaInput}
                                        name="metatagsDescription"
                                        label="Descrição"
                                        placeholder="Descrição para colocar em metatags"
                                    />
                                </Components.FormGroup>
                            </Components.Portlet>
                        </form>
                    );
                }}
            </Components.Form>
        </Components.ModalThatSlides>
    );
}

export default ModalCreate;
