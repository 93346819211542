import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Components, MediStrap } from "../../../MediStrap";
import { MUTATION_USER_DELETE, MUTATION_USER_UPDATE } from "../graphql/mutations";
import { QUERY_USER } from "../graphql/queries";

const MediStrapProperties = MediStrap.getProperties();

function ModalUpdate({ onRequestClose, onUpdate, onDelete, isOpen, userId }) {
    const updateFormRef = useRef(null);

    const [user, setUser] = useState(null);

    const [queryUser] = useLazyQuery(
        QUERY_USER,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.user) {
                    setUser(data.user);
                }
            }
        }
    );

    const requestUser = (params = {}) => {
        const { id } = params;

        queryUser({
            variables: {
                id
            }
        });
    }

    useEffect(() => {
        if (!!userId) {
            requestUser({ id: userId });
        }
    }, [userId]);

    const [mutationUserUpdate, { loading: loadingUserUpdate }] = useMutation(
        MUTATION_USER_UPDATE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    onUpdate(data.userUpdate);
                    onRequestClose();
                }
            }
        }
    );

    const updateUser = (params = {}) => {
        const { name, nif, type, email, password } = params;

        mutationUserUpdate({
            variables: {
                id: user.id,
                name,
                email,
                password
            }
        });
    }

    const [mutationUserDelete, { loading: loadingUserDelete }] = useMutation(
        MUTATION_USER_DELETE,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.userDelete) {
                    onDelete();
                    onRequestClose();
                }
            }
        }
    );

    const deleteUser = () => {
        mutationUserDelete({
            variables: {
                id: user.id
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setUser(null);
        }
    }, [isOpen])

    return (
        <Components.Modal
            title="Editar conta"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            rightActions={
                <Components.Block row center>
                    <Components.Block>
                        <Components.Button type="submit" loading={loadingUserUpdate} size="medium" label="Editar conta" color="brand" onClick={() => updateFormRef.current.handleSubmit()} />
                    </Components.Block>
                </Components.Block>
            }
        >
            {!!user ?
                <>
                    <Components.Form
                        innerRef={updateFormRef}
                        initialValues={{
                            name: user.name,
                            email: user.email
                        }}
                        schema={yup.object().shape({
                            name: yup.string().required(),
                            email: yup.string().email().required(),
                            password: yup.string()
                        })}
                        onSubmit={(values) => {
                            updateUser(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Components.FormGroup title="Geral">
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.name} Component={Components.TextInput} name="name" label="Nome" placeholder="Primeiro e último nome" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup title="Credênciais">
                                        <Components.Block width={420}>
                                            <Components.Input error={errors.email} Component={Components.TextInput} name="email" label="Email" placeholder="Email" />
                                        </Components.Block>
                                        <Components.Block width={334}>
                                            <Components.Input error={errors.password} Component={Components.PasswordInput} name="password" label="Password" placeholder="Password" description="Password para acesso a plataforma" />
                                        </Components.Block>
                                    </Components.FormGroup>
                                    <Components.FormGroup>
                                        <Components.DeleteLine
                                            title="Eliminar conta"
                                            description="Esta ação irá eliminar a conta permanentemente "
                                            onClickDelete={() => deleteUser({ id: user.id })}
                                            deleteButtonLoading={loadingUserDelete}
                                        />
                                    </Components.FormGroup>
                                </form>
                            )
                        }}
                    </Components.Form>
                </>
                :
                <Components.Block center middle>
                    <Components.Loading color={MediStrapProperties.colors.brand100} />
                </Components.Block>}
        </Components.Modal>
    );
}

export default ModalUpdate;
