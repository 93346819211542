import gql from "graphql-tag";

export const MUTATION_USER_CREATE = gql`
    mutation userCreate($name: String!, $email: String!, $password: String!) {
        userCreate (name: $name, email: $email, password: $password){
            id
            name
            email
        }
    }
`;
export const MUTATION_USER_UPDATE = gql`
    mutation userUpdate($id: ID!, $name: String!, $email: String!, $password: String) {
        userUpdate (id: $id, name: $name, email: $email, password: $password){
            id
            name
            email
        }
    }
`;
export const MUTATION_USER_DELETE = gql`
    mutation userDelete($id: ID!) {
        userDelete (id: $id) {
            id
            name
            email
        }
    }
`;